import React from "react";
import "../stylesheets/SettingAndBillingTabs.css";
import SettingTabForm from "./SettingTabForm/SettingTabForm";

const SettingAndBillingTabs = () => {
  return (
    <div className="SettingAndBillingTabs">
      <div className="UserProfileBottom">
        <div className="container" style={{ padding: "0px" }}>
          <div className="tabs">
            <div className="tab-buttons" style={{ width: "100%!important" }}>
              <button
                style={{ width: "100%!important" }}
                className={["dog-btn tab-btn", "active"].join(" ")}
              >
                Settings
              </button>
              
            </div>
            {/* <div className="tab-content">{tabHtml}</div> */}
          </div>
        </div>
      </div>
      <div className="container" style={{ padding: "0px" }}>
        {<SettingTabForm />}
      </div>
    </div>
  );
};

export default SettingAndBillingTabs;
