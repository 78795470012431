import React, { useEffect, useState } from 'react';
import '../../stylesheets/Followers.css';
import { connect } from 'react-redux';
import { axios_getUserFollowers, axios_getUserFollowing } from '../../../utils/axios-routes';
import SearchItem from '../../SearchPage/SearchItem/SearchItem';
import { Link } from 'react-router-dom';
const Following = (props) => {

  const [followings, setFollowings] = useState([])
  useEffect(() => {
    axios_getUserFollowing().then(res => {
      console.log(res.data)
      setFollowings(res.data.users)
    })
  }, [])

  return (
    <div className='Followers'>
      {followings?.length > 0 ? (
        followings.map((following, index) => {
          return (
            <div className='mt-3' key={index}>
              {/* <Follower follower={follower} /> */}
              <SearchItem user={following} />
            </div>
          )
        })
      ) : (
        <p className='mt-3 font-weight-bold light-color'>
          You are not following anyone yet.  <Link href="/search" underline="hover" style={{color:"var(--cta-color)",textDecoration:"none"}}>Follow your friends</Link>.
        </p>
      )}
    </div>
  )
}

const mapStatetoProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading
})
export default connect(mapStatetoProps, {})(Following)
