import React, { useState,useEffect } from 'react';
import '../../stylesheets/EventsAndResults.css';

import ResultList from './ResultList/ResultList';
import AddResult from './AddResult/AddResult';


import { useHistory, useParams } from 'react-router-dom';
import EditResult from './EditResult/EditResult';

const EventsAndResults = (props) => {
  const [addingResult, setaddingResult] = useState(false);
  const [editingResult, seteditingResult] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const params=useParams();
  const history=useHistory();


  const onAddResultClick = (e) => {
    history.push('/profile/results/add')
  };

  const onSubmitAddDogForm = (e) => {
    setShowForm(true);
  };

  let html=addingResult === false ? (
    <ResultList onAddResultClick={onAddResultClick} />
  ) : (
    <div>
      <AddResult
        showForm={showForm}
        setaddingResult={setaddingResult}
        onSubmitAddDogForm={onSubmitAddDogForm}
      />
    </div>
  )

  if(editingResult===true){
      html=<EditResult/>
  }




  useEffect(() => {
    if (params.operation === "add") {
       setaddingResult(true);
    } else if (params.operation === "edit") {
      seteditingResult(true)
      
    }else if (params.operation===undefined){
   
      // history.push('/profile/results')
      setaddingResult(false);
      setShowForm(false);
      seteditingResult(false)
    }
  },[params.operation]);


  return (
    <div className="EventsAndResults">
      {html}
    </div>
  );
};

export default EventsAndResults;
