import React, { useEffect, useState } from "react";

import "../stylesheets/UserProfile.css";
import Profile from "../../components/UserProfile/Profile/Profile";
import ProfileBottom from "../../components/UserProfile/ProfileBottom/ProfileBottom";
import { axios_getUserProfileByUsername } from "../../utils/axios-routes";

const UserProfile = (props) => {
  // console.log(props.match)
  const username = props.match.params.username;
  const [userData, setUserData] = useState(undefined);
  useEffect(() => {
    axios_getUserProfileByUsername(username).then((res) => {
      console.log(res);
      if (res.data.success) {
        setUserData(res.data.user);
      } 
    });
  }, [username]);

  return (
    <div id="UserProfile">
      {userData ? (
        <div>
          <div className="Profile-parent">
            <Profile user={userData} />
          </div>
          <ProfileBottom user={userData} />
        </div>
      ) : (
        <div style={{textAlign:"center",paddingTop:"50px"}}>
          {" "}
          <h5>
            No Profile found with username <strong>{username}</strong>
          </h5>
          <a href="/search" className="btn btn-warning mt-5" style={{color:"black"}}><i className="fa fa-arrow-left "></i> Back</a>
        </div>
      )}
    </div>
  );
};

export default UserProfile;
