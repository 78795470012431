import React from "react";
import { Button, Modal, Grid } from "@mui/material";
import LegalModal from "./LegalModal";

const Legal = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const closeModal = () => setOpen(false);
  return (
    <Grid item xs={12}>
      <Button style={{ color: "white" }} onClick={handleOpen}>
        Terms and Conditions | Privacy Policy
      </Button>{" "}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <LegalModal closeModal={closeModal}/>
      </Modal>
    </Grid>
  );
};

export default Legal;
