import React from 'react';
import '../../../stylesheets/Follower.css';
import SearchItem from '../../../SearchPage/SearchItem/SearchItem';
// import SearchItem from './SearchItem/SearchItem'
const Following = ({users}) => {
 
  
  return (
    <div className="Followers">
      {users?.length > 0 ? (
        users?.map((following, index) => {
          return (
            <div className="mt-3" key={index}>
            
               <SearchItem user={following} /> 
            </div>
          );
        })
      ) : (
        <p className="mt-3 font-weight-bold light-color">
          No following yet
        </p>
      )}
    </div>
  );
};

export default Following;
