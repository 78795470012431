import * as atypes from "../actions/types";


const initial_state = {
  token: localStorage.getItem("token"),
  isAuthenticated: null,
  loading: false,
  reqLoading: false,
  user: null
};

const authReducer = (state = initial_state, action) => {
  const { type, payload } = action;
  switch (type) {
    case atypes.REGISTER_SUCCESS:
      return {
        ...state,
        ...payload,
        isAuthenticated: false,
        loading: false
      };
    case atypes.LOAD_USER:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: payload
      }
    case atypes.LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
      }
    case atypes.START_LOADING:
      return {
        ...state,
        loading: true
      }

    case atypes.STOP_LOADING:
      return {
        ...state,
        loading: false
      }

    case atypes.SET_REQ_LOADING:
      return {
        ...state,
        reqLoading: payload.loading
      }

    

    case atypes.REGISTER_FAIL:
    case atypes.AUTH_ERROR:
    case atypes.LOGIN_ERROR:
    case atypes.LOGOUT:
    case atypes.ACCOUNT_DELETED:

      // localStorage.removeItem('userToken')
      return {
        ...state,
        user: null,
        isAuthenticated: false,
        loading: false

      }
    default:
      return state;

  }
}

export default authReducer