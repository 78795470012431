import React from "react";
import { Button, Grid, Typography, Paper, Container } from "@mui/material";
import { styled } from "@mui/material/styles";
import benefits from "../img/benefit/BenefitsGraphic.png";

const BenefitTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.primary,
  paddingTop: "1rem",
  fontWeight: "600",
  fontSize: "1.7rem",
}));

const BenefitButton = styled(Button)(({ theme }) => ({
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    color: "white",
  },
  fontSize: "1rem",
  fontWeight: "600",
  padding: "0.7rem 1.5rem",
}));

const Benefitspawcker = () => {
  return (
    <Container style={{ textAlign: "center" }} maxWidth="lg">
      <Grid container spacing={5} justifyContent="center">
        <Grid item xs={10} sm={6} md={6}>
          <BenefitTitle>HOW IT WORKS</BenefitTitle>
          <Grid style={{marginTop: "2em"}}>
          <iframe
            width="100%"
            height="300"
            src="https://www.youtube.com/embed/uuFsjKuNpmQ"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
          </Grid>
        </Grid>
        <Grid item xs={10} sm={6} md={6}>
          <BenefitTitle>BENEFITS OF PAWCKER</BenefitTitle>
          <img src={benefits} style={{ width: "70%", marginTop: "2rem" }} />
        </Grid>
      </Grid>
      <BenefitButton
        style={{ marginTop: "4rem" }}
        href="/register"
        size="large"
        variant="contained"
      >
        Get Started
      </BenefitButton>
    </Container>
  );
};

export default Benefitspawcker;
