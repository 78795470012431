import React from "react";
import { useHistory } from "react-router";

const ACC_Agility = (props) => {

    // console.log(props)
    const history = useHistory();
    return (
        <div className="ACC_Agility row">
            <div className="col-md-6">
                <div className="form-group">
                    <select
                        onChange={(e) => props?.handleOnChangeInput(e)}
                        className="form-control-select"
                        name="dog"
                        value={props?.fields?.dog}
                        placeholder="Dog"
                        required
                    >
                        <option value="" defaultValue disabled>
                            Dog
                        </option>
                        {props?.dogList?.map((d) => {
                            return <option value={d._id}>{d.name}</option>;
                        })}
                    </select>
                </div>

                {props?.fields?.dog !== "" && (
                    <div className="form-group">
                        <select
                            onChange={(e) => props?.handleOnChangeInput(e)}
                            name="organization"
                            value={props?.fields?.organization}
                            className="form-control-select"
                            placeholder="Organization"
                            required
                        >
                            <option value=""  defaultValue disabled>
                                Organization
                            </option>
                            {props?.orgList?.map((d) => {
                                return <option value={d.name}>{d.name}</option>;
                            })}
                        </select>
                    </div>
                )}

             
                    <div className="form-group">
                        <select
                            onChange={(e) => props?.handleOnChangeInput(e)}
                            name="class"
                            value={props?.fields?.class}
                            className="form-control-select"
                            placeholder="Level"
                            required
                        >
                            <option value="" defaultValue disabled>
                                Class
                            </option>
                            {props?.classList?.map((cls) => {
                                return <option value={cls.name}>{cls.name}</option>;
                            })}
                        </select>
                    </div>
                

                {/* Level */}
                {(props?.fields?.class !== "" && !(props?.fields?.class==="Challenge" || props?.fields?.class==="Team relay" || props?.fields?.class==="Steeplechase")) && (
                    <div className="form-group">
                        <select
                            onChange={(e) => props?.handleOnChangeInput(e)}
                            name="level"
                            value={props?.fields?.level}
                            className="form-control-select"
                            placeholder="Level"
                            required
                        >
                            <option value="" defaultValue disabled>
                                Level
                            </option>
                            {props?.levelList?.map((lvl) => {
                                return <option value={lvl.name}>{lvl.name}</option>;
                            })}
                        </select>
                    </div>
                )}

                {/* Category */}
                {(props?.fields?.level !== "") && (
                    <div className="form-group">
                        <select
                            onChange={(e) => props?.handleOnChangeInput(e)}
                            name="category"
                            value={props?.fields?.category}
                            className="form-control-select"
                            placeholder="Placement"
                            required
                        >
                            <option value="" defaultValue disabled>
                                Category
                            </option>
                            {props?.categoryList?.map((plc) => {
                                return <option value={plc.name}>{plc.name}</option>;
                            })}
                        </select>
                    </div>
                )}
                {/* Other fields */}
                {props.fields?.category !== "" && (
                    <>
                        <div className="form-group">
                            <input
                                name="points"
                                placeholder="Points"
                                value={props?.fields?.points}
                                type="number"
                                className="form-control-select"
                                onChange={props?.handleOnChangeInput}
                            />
                        </div>

                        <div className="form-group">
                            <input
                                name="date"
                                placeholder="date*"
                                value={props?.fields?.date}
                                type="date"
                                className="form-control-select"
                                onChange={props?.handleOnChangeInput}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                name="time"
                                placeholder="Time (seconds)*"
                                value={props?.fields?.time}
                                type="number"
                                className="form-control-select"
                                onChange={props?.handleOnChangeInput}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                name="judge"
                                placeholder="Judge*"
                                value={props?.fields?.judge}
                                type="text"
                                // required={props.fields?.qualified && props.fields?}
                                className="form-control-select"
                                onChange={props?.handleOnChangeInput}
                            />
                        </div>
                        <div className="form-group">
                    <input
                        name="qualified"
                        id="qualified"
                        placeholder=""
                        checked={props.fields?.qualified}
                        type="checkbox"
                        className=""
                        onChange={(e) => {
                            props?.handleOnChangeInput({
                                target: {
                                    name: "qualified",
                                    value: e.target.checked,
                                },
                            });
                        }}
                    />
                    <label
                        htmlFor="qualified"
                        style={{
                            fontSize: "17px",
                            marginLeft: "5px",
                            marginTop: "-2px",
                            fontWeight: 600,
                            color: "darkgray",
                        }}
                    >
                        Qualified
                    </label>
                </div>
                    </>
                )}

            
            </div>
            <div
                className="col-md-6 "
                style={{ minHeight: "300px", position: "relative" }}
            >
                {props?.fields?.category !== "" && (
                    <>
                        <div className="form-group">
                            <textarea
                                name="qDowell"
                                placeholder="What did you do well?*"
                                value={props?.fields?.qDowell}
                                rows="5"
                                type="text"
                                className="form-control-select"
                                onChange={props?.handleOnChangeInput}
                            ></textarea>
                        </div>
                        <div className="form-group">
                            <textarea
                                name="qImproveOn"
                                placeholder="What do you want to improve?*"
                                value={props?.fields?.qImproveOn}
                                type="text"
                                rows="5"
                                className="form-control-select mb-4"
                                onChange={props?.handleOnChangeInput}
                            ></textarea>
                            {/* <hr/> */}
                            <button type="submit" className="result-btn ">
                                Save
                            </button>
                        </div>
                    </>
                )}
            </div>
            <div className="col-md-12">
                <div></div>
            </div>
            <div className="col-md-6"></div>
            <div className="col-md-6">
                <button
                    type="submit"
                    className="result-btn-back"
                    onClick={() => {
                        history.push('/profile/results')
                    }}
                >
                    Cancel
                </button>

                <p className="mt-4">* Indicates optional field</p>
            </div>
        </div>
    );
};

export default ACC_Agility;
