import React, { useEffect, useState } from 'react';
import { DOG_TAB, EVENT_TAB, FOLLOWERS_TAB,FOLLOWING_TAB } from '../../utils/constants';
import '../stylesheets/UserProfileBottom.css';

import Dogs from './Dogs/Dogs';
import Followers from './Followers/Followers';
import EventsAndResults from './EventsAndResults/EventsAndResults';
import { useHistory, useParams } from 'react-router-dom';
import Following from './Followers/Following';
import { connect } from 'react-redux';

const ProfileBottom = (props) => {
  const [currentTab, setCurrentTab] = useState(DOG_TAB);
  const [tabsCount, setTabCount] = useState({followers:0,following:0});

  const handleChangeTab = (tab) => {
    setCurrentTab(tab);
    if(tab===DOG_TAB){
 
      history.push('/profile/dogs')

    }
    else if(tab===EVENT_TAB){
      history.push('/profile/results')
    }
    else if(tab===FOLLOWERS_TAB){
      history.push('/profile/followers')
     
    }
    else if(tab===FOLLOWING_TAB){
      history.push('/profile/following')
     
    }

  };

  console.log(tabsCount)
  const params=useParams();
  const history=useHistory()
  let tabHtml = <Dogs />;
  if (currentTab === EVENT_TAB) {
    tabHtml = <EventsAndResults setCurrentTab={setCurrentTab} />;
  } else if (currentTab === FOLLOWERS_TAB) {
    tabHtml = <Followers  />;
  

} else if (currentTab === FOLLOWING_TAB) {
  tabHtml = <Following />;
}



  useEffect(()=>{
    if(params.tab==='dogs'){
      setCurrentTab(DOG_TAB);
               

    }
    else if(params.tab==='results'){
      setCurrentTab(EVENT_TAB)
    }
    else if(params.tab==='followers'){
      setCurrentTab(FOLLOWERS_TAB)
    }
    else if(params.tab==='following'){
      setCurrentTab(FOLLOWING_TAB)
    }
  },[])

  return (
    <div className="UserProfileBottom">
      <div className="container" style={{ padding: '0px' }}>
        <div className="tabs">
          <div className="tab-buttons">
            <button
              className={[
                'dog-btn tab-btn',
                currentTab === DOG_TAB ? 'active' : '',
              ].join(' ')}
              onClick={() => handleChangeTab(DOG_TAB)}
            >
              Dogs
            </button>
            <button
              className={[
                'event-btn tab-btn',
                currentTab === EVENT_TAB ? 'active' : '',
              ].join(' ')}
              onClick={() => handleChangeTab(EVENT_TAB)}
            >
              Results
            </button>
            <button
              className={[
                'follower-btn tab-btn',
                currentTab === FOLLOWERS_TAB ? 'active' : '',
              ].join(' ')}
              onClick={() => handleChangeTab(FOLLOWERS_TAB)}
            >
              Followers ({props.user?.followersLength||0})
            </button>
            <button
              className={[
                'follower-btn tab-btn',
                currentTab === FOLLOWING_TAB ? 'active' : '',
              ].join(' ')}
              onClick={() => handleChangeTab(FOLLOWING_TAB)}
            >
              Following ({props.user?.followingLength||0})
            </button>
          </div>
          <div className="tab-content">{tabHtml}</div>
        </div>
      </div>
    </div>
  );
};

const mapStatetoProps = state => ({
  user:state.auth.user
});

export default connect(mapStatetoProps,{})(ProfileBottom);
