import React from "react";
import { Box, Tabs, Tab, Typography, Grid, IconButton } from "@mui/material";
import TandC from "./TandC";
import PrivacyPolicy from "./PrivacyPolicy";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  overflow: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const LegalModal = (props) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={style}>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        style={{ paddingTop: "0.5rem", paddingRight: "0.5rem" }}
      >
        <IconButton aria-label="delete" onClick={props.closeModal} s>
          <CloseIcon />
        </IconButton>
      </Grid>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
      >
        <Tab label="Terms & Conditions" style={{ fontSize: "0.8rem" }} />
        <Tab label="Privacy Policy" style={{ fontSize: "0.8rem" }} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <TandC />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <PrivacyPolicy />
      </TabPanel>
    </Box>
  );
};

export default LegalModal;
