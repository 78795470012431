import React from 'react';

import { Link } from 'react-router-dom';
import {connect} from 'react-redux'
import { startLoading,stopLoading } from '../../store/actions/auth';
import '../stylesheets/Profile.css';
import { USER_PROFILE_PATH } from '../../utils/constants';

const Profile = (props) => {

  return (
    <div className="Profile">
      <div className="container">
     
            <div className="my-profile">
              <button className="my-profile-img">
                <img src={USER_PROFILE_PATH+props?.user?.profilePic} alt="Profile Pic" />
              </button>

              <div className="profile-data m-auto">
                <h5 className="heading">{props.user?.firstName} {props.user?.lastName}</h5>
                <Link to="/settings">
                  <i className="fas fa-cog heading-icon"></i>
                </Link>
                <p className="email mb-0">@{props.user?.username}</p>
                <p className="contender mb-2">{props.user?.participantType}</p>

                <p className="medal">
                  {/* {item.medal} */}
                  {props?.user?.resultsLength}
                  <i className="fas fa-medal"></i>
                </p>

                <p className="paw">
                  {props?.user?.dogsLength}
                  <i className="fas fa-paw"></i>
                </p>
                <p className="user">
                  {props?.user?.followersLength} 
                  <i className="fas fa-user"></i>
                </p>
              </div>
            </div>
     
      </div>
    </div>
  );
};
const mapStatetoProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
  user:state.auth.user
});
export default connect(mapStatetoProps, {startLoading,stopLoading})(Profile);




