import * as atypes from "./types";
// import { setAlert } from "./alert";
// import setAuthToken from "../../utills/setAuthToken";
import { setToast } from "./toast";
import {
  axios_loadUser,
  axios_loginUser,
  axios_registerUser,
} from "../../utils/axios-routes";

// import Cookies from 'js-cookie'

export const loginUser = (email, password, rememberme) => async (dispatch) => {
  dispatch({
    type: atypes.START_LOADING,
  });

  axios_loginUser(email, password)
    .then((response) => {
      if (response.data.success) {
        dispatch({
          type: atypes.LOGIN_SUCCESS,
          payload: { token: response.data.token },
        });
        localStorage.setItem("userToken", response.data.token);
    
          window.location.href = '/';

        // if (rememberme) {
        //   Cookies.set('email', email, { expires: 14 })
        //   Cookies.set('password', password, {
        //     expires: 14
        //   })
        //   Cookies.set('rememberme', true, { expires: 14 })
        // } else {
        //   Cookies.set('email', '')
        //   Cookies.set('password', '')
        //   Cookies.set('rememberme', '')
        // }
      } else {
        dispatch({
          type: atypes.LOGIN_ERROR,
        });

        dispatch({
          type: atypes.SET_TOAST,
          payload: [{ msg: "Invalid Credentials", type: "error" }],
        });
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: atypes.LOGIN_ERROR,
      });
      dispatch({
        type: atypes.SET_TOAST,
        payload: [{ msg: "Invalid Credentials", type: "error" }],
      });
    });
};

export const loadUser = (deleteToken=true)=> async (dispatch) => {
  dispatch({
    type: atypes.START_LOADING,
  });

  axios_loadUser()
    .then((response) => {
      dispatch({
        type: atypes.STOP_LOADING,
      });
   
      if (response.data.success) {
        dispatch({
          type: atypes.LOAD_USER,
          payload: response.data.data,
        });
      } else {
   
        setTimeout(() => {
          dispatch({
            type: atypes.SET_TOAST,
            payload: [],
          });
        }, 10000);
        dispatch({
          type: atypes.AUTH_ERROR,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: atypes.AUTH_ERROR,
      });

    });
};

export const logout = () => (dispatch) => {
  localStorage.removeItem("userToken");
  dispatch({
    type: atypes.LOGOUT,
  });
  window.location.href = "/";
};

export const startLoading = () => (dispatch) => {
  dispatch({
    type: atypes.START_LOADING,
  });
};

export const stopLoading = () => (dispatch) => {
  dispatch({
    type: atypes.STOP_LOADING,
  });
};

export const setReqLoading = (loading=true) => (dispatch) => {
  dispatch({
    type: atypes.SET_REQ_LOADING,
    payload:{loading:loading}
  });
};



export const isEmailRegisterd = (email) => async (dispatch) => {
  // await axios
  //   .get("/api/auth/checkemail")
  //   .then(response => {
  //     dispatch({
  //       type: atypes.LOAD_USER,
  //       payload: response.data
  //     });
  //     console.log(response.data);
  //   })
  //   .catch(err => {
  //     dispatch({
  //       type: atypes.AUTH_ERROR
  //     });
  //   });
};

export const registerUser = (data) => async (dispatch) => {
  dispatch({
    type: atypes.START_LOADING,
  });
  // const body = JSON.stringify({ fname, lname, email, password, username });
  // try {
  axios_registerUser(data)
    .then((response) => {
      if (response.data.success) {
        dispatch({
          type: atypes.REGISTER_SUCCESS,
          payload: response.data,
        });

        dispatch(setToast([{ msg: response.data.msg, type: "success" }]));

        setTimeout(() => {
          window.location.href = "/login";
        }, 4000);
      } else {
        dispatch(setToast([{ msg: response.data.msg, type: "warning" }]));
      }

      dispatch({
        type: atypes.STOP_LOADING,
      });
    })
    .catch((err) => {
      const errors = [];
      if (err.response) {
        if (err?.response?.data?.errors) {
          let ar_err = err.response.data.errors;
          console.log(ar_err);
          ar_err.map((e) => {
            return errors.push({ msg: e.param + " : " + e.msg, type: "error" });
          });
          dispatch(setToast(errors));
        }
      } else {
        dispatch(
          setToast([
            { msg: "Somthing went wrong! Register Failed", type: "error" },
          ])
        );
      }

      dispatch({
        type: atypes.STOP_LOADING,
      });
      dispatch({
        type: atypes.REGISTER_FAIL,
      });
    });
};

export const resetPassword =
  ({ newPassword, confirmPassword, token }) =>
  async (dispatch) => {
    // const config = {
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    // };
    // const body = JSON.stringify({ newPassword, confirmPassword, token });
    // try {
    // await axios
    //   .post("/api/auth/reset-password", body, config)
    //   .then(response => {
    //     // dispatch({
    //     //   type: atypes.RESET_PASSWORD,
    //     //   payload: response.data
    //     // });
    //     console.log(response.data.found);
    //     if (response.data.found) {
    //       dispatch(setToast([{ msg: "Password reset successfully.Redirecting to Login page", type: "success" }]));
    //       setTimeout(() => {
    //         window.location.href = "/login"
    //       }, 3000);
    //     } else {
    //       dispatch(setToast([{ msg: "Somthing went wrong.Password not updated.Redirecting to Login page", type: "error" }]));
    //     }
    //   })
    //   .catch(err => {
    //     // dispatch({
    //     //   type: atypes.REGISTER_FAIL
    //     // });
    //   });
  };
