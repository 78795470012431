import React from 'react';
import '../../../stylesheets/DogList.css';
import Dog from './Dog';


const DogList = (props) => {
  // console.log("oh yes")
  // console.log(props)
  return (
    <div className="DogList">
      <div className="dogs-parent">
        {props?.dogs?.length > 0 ? (
          props?.dogs?.map((dog, index) => {
            return (
              <div className="mt-3" key={index}>
                <Dog dog={dog} />
              </div>
            );
          })
        ) : (
          <p className="para font-weight-bold light-color">
            No dogs registered yet
          </p>
        )}
      </div>
    </div>
  );
};

export default DogList;
