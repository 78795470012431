import React, { useState, useEffect } from "react";
import {
  axios_deleteDog,
  axios_getUserDogs,
} from "../../../../utils/axios-routes";
import Dog from "../Dog/Dog";
import queryString from "query-string";
import { connect } from "react-redux";
import { setToast } from "../../../../store/actions/toast";
import ModalBadge from '../../../layouts/ModalBadge/ModalBadge'
import {
  loadUser,
  startLoading,
  stopLoading,
} from "../../../../store/actions/auth";

const DogList = (props) => {
  const [dogs, setDogs] = useState([]);
  const [selectedDog, setSelectedDog] = useState();

  const handleSelectedDog = (dog) => {

    setShow(true)
    setSelectedDog(dog)
  }
  const [show, setShow] = useState(false);
  const handleRemoveDog = (id) => {
    props.startLoading();

    axios_deleteDog(id).then((res) => {
      if (res.data.success) {
        axios_getUserDogs().then((res) => {
          props.stopLoading();

          if (res.data.success) setDogs(res.data.dogs);
          setShow(false)
          props.setToast([
            { type: "success", msg: "Dog Deleted successfully" },
          ]);
          props.loadUser();
        });
      }
    });
  };

  useEffect(() => {
    const parsed = queryString.parse(window.location.search);
    if (parsed.paymentsuccess) {
      props.setToast([
        {
          type: "success",
          msg: "Payment successfull! you are allow to add one dog",
        },
      ]);
    }

    props.startLoading();

    axios_getUserDogs().then((res) => {
      // console.log(res.data)
      if (res.data.success) {
        setDogs(res.data.dogs);
      }
      props.stopLoading();
    });
  }, []);

  return (
    <div className="DogList">
      <ModalBadge show={show} title="" loading={props.loading} style={{textAlign:"center"}} noText={"Close"} onAccept={()=>handleRemoveDog(selectedDog?._id)}  onCancel={()=>{setShow(false);setSelectedDog({})}} onHide={()=>{setShow(false);setSelectedDog({})}} yesText={"Confirm"} body={<div><h4>Are you sure you want to delete <br/><strong>{selectedDog?.name}</strong>?</h4><p>This action is irreversible.</p></div>} />
      <div className="dogs-parent">
        <div style={{ overflow: "hidden" }}>
          <button
            href="!"
            className="add-btn"
            onClick={(e) => props.onAddDogClick()}
          >
            <i className="fas fa-plus-square"></i>
          </button>
        </div>

        {dogs.length > 0 ? (
          dogs.map((dog, index) => {
            return (
              <div className="mt-3" key={index}>
                <Dog dog={dog} handleSelectedDog={handleSelectedDog} handleRemoveDog={handleRemoveDog} />
              </div>
            );
          })
        ) : (
          <p className="para font-weight-bold light-color">
            You have no dogs registered yet
          </p>
        )}
      </div>
    </div>
  );
};

const mapStatetoProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
});

export default connect(mapStatetoProps, {
  setToast,
  startLoading,
  stopLoading,
  loadUser,
})(DogList);
