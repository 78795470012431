  import * as atypes from "../actions/types";


const initial_state = {
    dogs:[]
};

const dogReducer= (state = initial_state, action)=> {
  const { type, payload } = action;
  switch (type) {
    case atypes.CREATE_DOG:
      return {
        ...state
      };
    case atypes.GET_DOGS:
      return{
        ...state,
        loading:false,
        gods:payload
      }
    default:
        return state;
  
  }
}
export default dogReducer;