import *  as atypes from '../actions/types'
const initial_state=[]


const alertReducer=(state=initial_state,action)=>{


   const {type,payload}=action;


    switch (type) {
        case atypes.SET_ALERT:
            return[...state,payload]
        case atypes.REMOVE_ALERT:
           
            return state.filter(alert=>alert.id!==payload.id)   
    
        default:
            return state;
    
    }
}

export default alertReducer