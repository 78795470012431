import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { axios_followOrUnfollowUser } from "../../../utils/axios-routes";

import { USER_PROFILE_PATH } from "../../../utils/constants";
import '../../stylesheets/UserProfile.css';
const Profile = ({ user, isAuthenticated, userRedux, loading }) => {
  // console.log(user)
  const [follow, setFollow] = useState(false);

  useEffect(() => {
    setFollow(user.followed);
  }, [user.followed]);

  const onClickFollow = () => {
    axios_followOrUnfollowUser(user._id).then((res) => {
      setFollow(res.data.follow);
    });
  };
  console.log(user.followed, follow);
  return (
    <div className="Profile">
      <div className="container">
        <div className="my-profile">
          <div className="my-profile-img">
            <img src={USER_PROFILE_PATH + user?.profilePic} alt="Profile Pic" />
          </div>

          <div className="profile-data m-auto">
            <h5 className="heading">
              {user?.firstName} {user?.lastName}
            </h5>

            <p className="email mb-0">@{user?.username}</p>
            <p className="contender mb-2">{user?.participantType}</p>

            <p className="medal">
              {user.resultsLength}
              <i className="fas fa-medal"></i>
            </p>

            <p className="paw">
              {user?.dogsLength}
              <i className="fas fa-paw"></i>
            </p>
            <p className="user">
              {user?.followersLength}
              <i className="fas fa-user"></i>
            </p>
          </div>

          {user?._id !== userRedux?._id && (
            <div className="profile-follow-btn">
              <div className="follow-btn">
                <button
                  className={`btn ${follow === true ? `followed` : ``}`}
                  onClick={onClickFollow}
                >
                  {follow === true ? "Unfollow" : "Follow"}
                </button>
                <button
                  className={`btn-small  ${follow === true ? `followed` : ``}`}
                  onClick={onClickFollow}
                >
                  {follow === false ? (
                    <i className="fa fa-plus"></i>
                  ) : (
                    <i className="fa fa-minus"></i>
                  )}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStatetoProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
  userRedux: state.auth.user,
});
export default connect(mapStatetoProps, {})(Profile);
