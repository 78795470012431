import React, { Fragment } from "react";
import '../stylesheets/Spinner.css';
import spinner from "../img/spinner/snake1.gif";
const Spinner = ({ style, cls }) => {
  let html = (
    <div className="Spinner">
      <img src={spinner} style={style} alt="" />
    </div>
  );
  if (cls === "abs") {
    html = (
      <div className="text-center">
        <div className="back"></div>
        <div className={["Spinner", cls].join(" ")}>
          <img src={spinner} style={style} alt="" />
        </div>
      </div>
    );
  }
  return <Fragment>{html}</Fragment>;
};

export default Spinner;
