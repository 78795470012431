import React from "react";
import headerGraphic from "../img/slider/v1_3.png"

import { Button, Grid, Typography, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import { textAlign } from "@mui/system";

const Benefit = styled(Paper)(({ theme }) => ({
  textAlign: "left",
  opacity: '90%',
  padding: "2.5rem",
}));

const BenefitTitle = styled(Typography)(({ theme }) => ({
  marginBottom: '2rem',
  color: "white",
  fontWeight: '500',
  fontSize: "6vh",
}));

const BenefitText = styled(Typography)(({ theme }) => ({
  marginBottom: '2rem',
  fontSize: "100%",
}));

const BenefitButton = styled(Button)(({ theme }) => ({
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    color: 'white',
  },
  fontSize: "1rem",
  fontWeight: "600",
  padding: "0.7rem 1.5rem",
}));

const HeroPanel = () => {
  return (

      <Grid
        container
        direction="row"
        spacing={3}
        justifyContent="center"
        alignItems="center"
        style={{
            background: "#2a3166",
            height: "100vh",
        }}
      >
        <Grid item xs={10} sm={10} md={4}>
            <BenefitTitle variant="h2">YOUR
            <br /><span style={{color: "#f64c72"}}>DOG SPORTS</span>
            <br />POCKET
            <br />COMPANION</BenefitTitle>

            <BenefitButton
              href="/register"
              size="large"
              variant="contained"
            >
              Get Started
            </BenefitButton>
        </Grid>
        <Grid item xs={10} sm={10} md={6}>
          <img src={headerGraphic} style={{width: "100%"}} />
          <BenefitText variant="h5" style={{ color: "#99738e", textAlign: "center"}}>
            Track your results without a spreadsheet
            </BenefitText>
        </Grid>
      </Grid>

  );
};

export default HeroPanel;
