import React, { useEffect, useState } from "react";
import { axios_getallClubs, axios_getFilteredEvents } from "../../utils/axios-routes";
import "../stylesheets/LoggedInUpcommingCompetitions.css";
import states from "../data/states.json";
import dateFormat from "dateformat";
const LoggedInUpcommingCompetitions = () => {
 
  const [clubList, setClubList] = useState([]);
  const [eventList, setEventList] = useState([]);
  const [stateList] = useState(states?states:[]);


  const [formData, setFormData] = useState({
    club: "",
    state: "",
  });

  useEffect(() => {
    axios_getallClubs().then((res) => {
      setClubList(res?.data?.clubs||[]);
    }).catch(err=>{

    });
  
  }, []);

useEffect(()=>{

  axios_getFilteredEvents(formData).then(res=>{
    setEventList(res?.data?.events||[])

  }).catch(res=>{

  })
},[formData])

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <div className="LoggedInUpcommingCompetitions">
      <h6 className="heading">Upcoming Competitions</h6>
      <form className="form ">
        <div className="form-label">
          <label className="mb-1 font-weight-bold">Filter</label>
        </div>
        <div className="row">
          <div className="col-md-6 col-lg-4 text-dark">
            <select
              placeholder="Club"
              value={formData.club}
              className="form-select form-control"
              name="club"
              onChange={onChange}
            >
              <option value="" disabled>
                Club
              </option>
              {clubList&&clubList?.map((club) => {
                return <option value={club?._id} key={club?._id}>{club?.name}</option>;
              })}
            </select>
          </div>
          <div className="col-md-6 col-lg-4">
            <select
              className="text-dark form-select form-control"
              value={formData.state}
              name="state"
              onChange={onChange}
            >
              <option value="" disabled>
                State / Province
              </option>
              {stateList&&stateList?.map(state=>{
                return <option key={state.value}>{state.value}</option>
              })}
            </select>
          </div>
          <div className="col-md-2">
            <button type="button"className="btn btn-default" onClick={()=>setFormData({
                ...formData,
                club:"",
                state:""
              })
            
            }>Clear</button>
          </div>
        </div>
      </form>

      <div className="content" style={{overflowY:"scroll",overflowX:"hidden",maxHeight:"600px"}}>
        {eventList&&eventList.map((event, key) => {
          return (
            <div className="row content-item" key={event?._id}>
              <div className="col-md-6">
                <p className="">{event?.clubId?.name} | {event?.city}, {event?.state}</p>
              </div>
              <div className="col-md-6 ">
            
                <p className="">{dateFormat(event?.startDate,"dddd mmmm d")}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LoggedInUpcommingCompetitions;
