import React, { useState, useEffect } from "react";
import '../../stylesheets/SearchItem.css';
import { USER_PROFILE_PATH } from "../../../utils/constants";
import { axios_followOrUnfollowUser } from "../../../utils/axios-routes";
import { connect } from "react-redux";
const SearchItem = ({ user, reduxUser }) => {
  const [follow, setFollow] = useState(false);

  useEffect(() => {
    setFollow(user.followed);
  }, [user.followed]);
  const handleClickFollow = (e) => {
    e.preventDefault();
    axios_followOrUnfollowUser(user._id).then((res) => {
      if (res.data.success) {
        setFollow(res.data.follow);
      }
    });
  };
  console.log(user);
  return (
    <div className="SearchItem">
      <div className="item-container">
        <div className="search-item-img">
          <img src={USER_PROFILE_PATH + user.profilePic} alt="profile pic" />
        </div>

        <div className="profile-data  m-auto">
          <div className="data-container">
            <a href={"/user/" + user.username}>
              <h5 className="heading">
                {user.firstName} {user.lastName}
              </h5>

              <p className="email mb-0">@{user.username}</p>
            </a>

            <p className="medal">
              {user.resultsLength} <i className="fas fa-medal"></i>
            </p>
            <p className="paw">
              {user.dogsLength} <i className="fas fa-paw"></i>
            </p>
            <p className="user">
              {user.followersLength} <i className="fas fa-user"></i>
            </p>
          </div>
        </div>

        <div className=" follow-btnn">
          {reduxUser?._id !== user._id ? (
            <>
              <button
                onClick={handleClickFollow}
                className={`btn ${follow===true ? `followed` : ``}`}
              >
                {follow === true ? "Unfollow" : "Follow"}
              </button>
            </>
          ) : (
            <button disabled className="btn">
              Me
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStatetoProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
  reduxUser: state.auth.user,
});
export default connect(mapStatetoProps, {})(SearchItem);
