import React from 'react';

import '../stylesheets/SettingAndBillingTabs.css';

import Profile from '../../components/ProfilePage/Profile';
import SettingAndBillingTab from '../../components/SettingAndBillingTabs/SettingAndBillingTabs';

const SettingAndBillingTabs = () => {
  return (
    <div id="SettingAndBillingTabs">
      <div className="setting-parent">
        <Profile />
        <SettingAndBillingTab />
      </div>
    </div>
  );
};

export default SettingAndBillingTabs;
