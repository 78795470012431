import React from "react";
import './AddClubFrom.css'
class AddClubForm extends React.Component {
  constructor(props) {
    super(props);
  }

  
  render() {
  
    return (
      <div className="AddClubForm">
        <button className="close-btn" type="button" onClick={(e) => this.props.removeClub(this.props.index)}>
          <i className="fa fa-times"></i>
        </button>
        <select
          onChange={(e) => this.props.handleOnChangeInput(this.props.index, e)}
          name="organization"
          value={this.props.club.organization}
          className="form-control-select"
          // {...register('organization')}
          // className={`form-control-select ${
          //   errors.organization ? 'is-invalid' : ''
          // }`}
          placeholder="Organization"
        >
          {/* <div className="invalid-feedback">
{errors.organization?.message}
</div> */}
          <option value="" defaultValue hidden>
            Organization
          </option>
          {this.props.orgainzations.map((club) => {
            return <option value={club.name}>{club.name}</option>;
          })}
        </select>

        {this.props.club?.organization !== "" ? (
          <div className="row club-info-form-2">
            <div className="col-md-6">
              <div className="form-group">
                <input
                  id="name"
                  onChange={(e) =>
                    this.props.handleOnChangeInput(this.props.index, e)
                  }
                  name="name"
                  value={this.props.club.name}
                  type="text"
                  placeholder="Registered Name"
               
                />
         
                <div
                  className=""
                  style={{
                    color: "red",
                    fontSize: "14px",
                  }}
                >
                  {this.props.error?.name !== "" ? this.props.error?.name : ""}
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <input
                  id="number"
                  type="text"
                  onChange={(e) =>
                    this.props.handleOnChangeInput(this.props.index, e)
                  }
                  value={this.props.club.number}
                  name="number"
                  placeholder="Number"
             
                />
           
                <div
                  className=""
                  style={{
                    color: "red",
                    fontSize: "14px",
                  }}
                >
                  {this.props.error?.number !== ""
                    ? this.props.error?.number
                    : ""}
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default AddClubForm;
