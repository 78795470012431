import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import store from "./store/store";
import { Provider } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./pages/stylesheets/Index.css";

const primaryColor = "#242582";
const secondaryColor = "#2a3166";
const ctaColor = "#f64c72";
const minorColor = "#99738e";
const backgroundColor = "#ffffff";

const theme = createTheme({
  palette: {
    primary: {
      primary: primaryColor,
      main: ctaColor,
      light: "#F774B3",
      dark: "#AC3970",
      backgroundColor: backgroundColor,
    },
    secondary: {
      main: secondaryColor,
    },
    minor: {
      main: minorColor,
    },
    divider: minorColor,
  },
  typography: {
    fontFamily: 'Montserrat, Source Sans Pro, sans-serif',
    fontSize: 16,
    h1: {
      fontWeight: '600',
    },
    h2: {
      color: primaryColor,
      fontSize: 48,
      fontWeight: '600',
      marginTop: "2rem",
      marginBottom: "4rem",
    },
    h3: {
      fontSize: 32,
      color: primaryColor,
    },
    subtitle1: {
      fontSize: 16,
      color: secondaryColor,
    },
  },
  components: {
    MuiDataTable: {
      StyleOverrides: {
        root: {},
      },
    },
  },
});

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <React.StrictMode>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </React.StrictMode>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

reportWebVitals();
