import React, { useState, useEffect } from 'react';
import '../../../stylesheets/AddDog.css';
import Select from 'react-select';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { axios_getAllBreads } from '../../../../utils/axios-routes';
import { useHistory } from 'react-router-dom';
import { sortData } from '../../../../utils/functions';

const AddDog = props => {

 
  const history= useHistory()
  const validationSchema = Yup.object().shape({
    breed: Yup.object().shape({
      value: Yup.string().required('Breed is required')
    }),
    callName: Yup.string().required('Call Name is required'),
    // profilePic: Yup.string().required('Picture is required'),
    owner: Yup.string().required('Owner is required'),
    dob: Yup.string()
      .required('Date of Birth is required')
      .matches(
        /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
        'Date of Birth must be a valid date in the format YYYY-MM-DD'
      )
  })

  const [breeds, setbreeds] = useState([])
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  })

  console.log(errors)
  function onSubmit (data) {
    props.setData({
      ...props.data,
      ...data
    })
    if (props.data.breed) {
      props.onSubmitAddDogForm()
    } else {
    }
  }

  useEffect(() => {
    axios_getAllBreads().then(res => {
      if (res.data.success) {
        let options = []
        const data = sortData(res.data.breeds)
        data.map(org => options.push({ label: org?.name, value: org?._id }))
        setbreeds(options)
      }
    })

    setValue('callName', props.data.callName)
    setValue('breed', props.data.breed)
    setValue('owner', props.data.owner)
    setValue('height', props.data.height )
    setValue('weight', props.data.weight )
    setValue('dob', props.data.dob )
    setValue('breedType', props.data.breedType )

  }, [props.data,setValue])

  const onBreedChange = e => {
    console.log(e)

    props.setData({
      ...props.data,
      breed: e
    })

    setValue('breed', e, { shouldValidate: true })
    if(e.label!=="Mixed Breed"){
    setValue('breedType', '' )

    }
  }

  const onAttachmentChange = event => {
    if (event.target.files[0] !== undefined) {
      props.setData({
        ...props.data,
        profilePic: URL.createObjectURL(event.target.files[0]),
        orignalPic: event.target.files[0]
      })
      setValue('profilePic', URL.createObjectURL(event.target.files[0]), {
        shouldValidate: true
      })
    }
  }



 



console.log(props.data?.breed)

  return (
    <div className='AddDog'>
      <div className='add-dog'>
      <form
            className='add-dog-form'
            onSubmit={handleSubmit(onSubmit)}
            onReset={reset}
          >
        <div className='row'>
        
            <div className='col-md-3'>
              <div className='add-dog-img'>
                <button>
                  <img src={props.data.profilePic} alt='dog profile' />
                </button>
                <input
                  type='file'
                  id='profilepic'
                  onChange={onAttachmentChange}
                  accept='image/*'
                />{' '}
                <label htmlFor='profilepic'>
                  <i className='fa fa-edit upload-btn'></i>
                </label>
                
             
              </div>
              <div className='invalid-feedback' style={{display:"block"}}>
                  {errors.profilePic?.message}
                </div>
            </div>
            <div className='col-md-7'>
              <h5 className='heading'>Add Dog</h5>
              <div className='form-group text-left'>
                <Select
                  options={breeds}
                  value={props.data.breed}
                  onChange={onBreedChange}
                  placeholder='Breed'
              
                  required={true}
                  className={`${errors.breed ? 'is-invalid' : ''} select`}
                />
            
                <div className='invalid-feedback'>
                  {errors.breed?.value?.message}
                </div>
              </div>
              {props.data?.breed?.label==="Mixed Breed"&&<div className='form-group'>
                <input
                  name='breedType'
                  {...register('breedType')}
                  className={`form-control ${
                    errors.breedType ? 'is-invalid' : ''
                  }`}
                  type='text'
                  placeholder='Breed Type'
                />
                <div className='invalid-feedback'>
                  {errors.breedType?.message}
                </div>
              </div>
}
              <div className='form-group'>
                <input
                  name='callName'
                  {...register('callName')}
                  className={`form-control ${
                    errors.callName ? 'is-invalid' : ''
                  }`}
                  type='text'
                  placeholder='Call Name'
                />
                <div className='invalid-feedback'>
                  {errors.callName?.message}
                </div>
              </div>


              <div className='form-group'>
                <input
                  {...register('owner')}
                  className={`form-control ${errors.owner ? 'is-invalid' : ''}`}
                  name='owner'
                  type='text'
                  placeholder='Registered Owner'
                />
                <div className='invalid-feedback'>{errors.owner?.message}</div>
              </div>
              <div className='form-group'>
                <input
                  {...register('dob')}
                  className={`form-control ${errors.dob ? 'is-invalid' : ''}`}
                  name='dob'
                  type='date'
                  max={new Date().toISOString().split("T")[0]}
                  placeholder='DOB'
                />
                <div className='invalid-feedback'>{errors.dob?.message}</div>
              </div>
              <div className='form-group'>
                <input
                  {...register('height')}
                  className={`form-control ${
                    errors.height ? 'is-invalid' : ''
                  }`}
                  name='height'
                  type='number'
                  placeholder='Height (inches)*'
                />
                <div className='invalid-feedback'>{errors.height?.message}</div>
              </div>
              <div className='form-group'>
                <input
                  {...register('weight')}
                  className={`form-control ${
                    errors.weight ? 'is-invalid' : ''
                  }`}
                  name='weight'
                  type='number'
                  placeholder='Weight (kg)*'
                />
                <div className='invalid-feedback'>{errors.weight?.message}</div>
              </div>

              <button type='submit' className='btn add-dog-btn'>
                Save and Enter Club Information
              </button>
              <div>
              <button type='button' onClick={()=>{
                props.setaddingDog(false);
                history.push('/profile/dogs')
              }} className='btn add-dog-bt'>Cancel</button>
                
              </div>
            </div>
        </div>
        </form>

      </div>
    </div>
  )
}

export default AddDog
