import { combineReducers } from "redux";
import alertReducer from "./alert";
import toastReducer from "./toast";
import authReducer from "./auth";
import dogReducer from './dogs'




export default combineReducers({
  alerts: alertReducer,
  toasts: toastReducer,
  auth: authReducer,
  dogs:dogReducer

});



