import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { startLoading, stopLoading } from "../../store/actions/auth";
import MUIDataTable from "mui-datatables";

import dateFormat from "dateformat";
import "../stylesheets/Event.css";
import { axios_getFilteredEvents } from "../../utils/axios-routes";
import { Typography } from "@mui/material";

var months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const Event = (props) => {
  const [formData, setFormData] = useState({
    month: "",
  });
  const [eventList, setEventList] = useState([]);

  const getFilteredEvents = (empty = false) => {
    let data = {};

    if (empty === true) {
      data = { month: "" };
      setFormData(data);
    } else {
      data = { ...formData };
    }

    if (data?.month) {
      data.month = months.indexOf(data.month) + 1;
    }
    props.startLoading();
    axios_getFilteredEvents(data)
      .then((res) => {
        let events = res?.data?.events;

        if (events?.length > 0) {
          try {
            events = events?.map((event) => {
              const startDate =
                event?.startDate

              const endDate =
                event?.endDate

              return {
                Club: event?.clubId?.name || "",
                Host: event?.hostId?.name || "",
                Sport: event?.sportIds
                  ?.map((sport) => {
                    return sport?.name;
                  })
                  ?.toString(),
                "Start Date": event?.startDate
                  ? dateFormat(startDate, "UTC:ddd mmm dd")
                  : "",
                "End Date": event?.endDate
                  ? dateFormat(endDate, "UTC:ddd mmm dd")
                  : "",
                City: capitalize(event?.city),
                State: event?.state,

                " ": (
                  <div>
                    {" "}
                    <a
                      className="link-btn"
                      href={event?.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa fa-arrow-right"></i>
                    </a>
                  </div>
                ),
              };
            });

            setEventList(events);
          } catch (error) {

            alert(error)
          }
        }
        props.stopLoading();
      })
      .catch((err) => {
        props.stopLoading();
        console.log(err);
        alert(err)
      });
  };

  useEffect(() => {
    getFilteredEvents();
  }, [formData.month]);

  const columns = [
    "Club",
    {
      name: "Host",
      options: {
        filter: false,
      },
    },
    "Sport",
    {
      name: "Start Date",
      options: {
        filter: false,
      },
    },
    {
      name: "End Date",
      options: {
        filter: false,
      },
    },
    "City",
    "State",

    {
      name: " ",
      options: {
        filter: false,
      },
    },
  ];

  const options = {
    selectableRows: "none",
    filter: true,
    filterType: "dropdown",
    download: false,
    print: false,
    search: true,
    viewColumns: false,
    elevation: 0,
  };

  function capitalize(word) {
    const splitWord = word?.split(" ") || [];
    for (var i = 0; i < splitWord.length; i++) {
      splitWord[i] =
        splitWord[i]?.charAt(0)?.toUpperCase() + splitWord[i]?.slice(1);
    }
    return splitWord?.join(" ");
  }

  return (
    <div className="Event container">
      <div className="event-container">
        <Typography
          variant="h3"
          style={{ color: "#242582", marginBottom: "1rem" }}
        >
          Upcoming Events
        </Typography>
        <div className="row">
          <div className="col-md-12">
       
            {eventList?.length > 0 ? (
               <MUIDataTable
               title={""}
               selectableRowsHideCheckboxes={false}
               data={eventList}
               columns={columns}
               options={options}
             />
            ) : (
              "No event found"
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStatetoProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
});
export default connect(mapStatetoProps, { startLoading, stopLoading })(Event);
