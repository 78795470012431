import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import logo from "../../img/pawckerlogo.jpg";
import { connect } from "react-redux";
import { logout } from "../../../store/actions/auth";
import PersonIcon from "@mui/icons-material/Person";
import {
  AppBar,
  Container,
  Toolbar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Avatar,
  Typography,
  Button, 
} from "@mui/material";
import { styled } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import { useEffect } from "react";

const MenuButton = styled(Button)(({ theme,active }) => ({
  "&:hover": {
    color: '#99738e',
    fontWeight: '700',
  },
  borderTop:active?`2px solid ${theme.palette.primary.main}`:'2px solid transparent',
  color: "white",
  borderRadius: "0px",
  display: "block",
  paddingRight: "1rem",
  paddingLeft: "1rem",
}));

const Navbar = (props) => {

  const pages = [
    { title: "Home", url: "/" },
    { title: "About", url: "https://www.pawcker.com/about"},
    { title: "Events", url: "/events" },
    { title: "Find Friends", url: "/search" },
    { title: "Blog", url: "https://www.pawcker.com/blog" },
  ];


  useEffect(()=>{

    console.log(window.location.pathname)
  },[window.location.pathname])

  const settingsLoggedIn = [
    { title: "Profile", url: "/profile" },
    { title: "Settings", url: "/settings" },
  ];

  const settingsNotLoggedIn = [{ title: "Login", url: "/login" }];

  const settings = props.isAuthenticated
    ? settingsLoggedIn
    : settingsNotLoggedIn;

  const avatarLabel = `${props.user?.firstName.charAt(
    0
  )}${props.user?.lastName.charAt(0)}`;

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static" style={{ backgroundColor: "#242582" }}>
      <Container maxWidth="xl">
        <Toolbar >
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
          >
            <Link to="/">
              <img src={logo} alt="PAWCKER" className="col-md-12" />
            </Link>
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page.title}
                  style={{ paddingRight: "1rem" }}
                  active={window.location.pathname===page.url}
                  component="a"
                  href={page.url}
                >
                  <Typography textAlign="center" >{page.title}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
          >
            <Link to="/">
              <img src={logo} alt="PAWCKER" className="col-md-12" />
            </Link>
          </Typography >
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <MenuButton
                key={page.title}
                href={page.url}
                active={window.location.pathname===page.url}
                sx={{
                  my: 2
                }}
              >
                {page.title}
              </MenuButton>
            ))}
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                {props.user ? (
                  <Avatar>{avatarLabel}</Avatar>
                ) : (
                  <Avatar>
                    <PersonIcon />
                  </Avatar>
                )}
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting.title} component="a" href={setting.url}>
                  <Typography textAlign="center">{setting.title}</Typography>
                </MenuItem>
              ))}
              {props.isAuthenticated ? (
                <MenuItem key="logout" component="a" onClick={props.logout}>
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
              ) : (
                " "
              )}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

const mapStatetoProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
  user: state.auth.user,
});
export default connect(mapStatetoProps, { logout })(Navbar);
