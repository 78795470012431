import React, { useState,useEffect } from 'react';
import '../../stylesheets/SettingTabForm.css';
import settingImage from '../../img/profile/dummy-profile.png';
import { axios_getProfileData, axios_updateProfileData } from '../../../utils/axios-routes';
import {connect} from 'react-redux'
import {startLoading,stopLoading,loadUser} from '../../../store/actions/auth';
import {setToast} from '../../../store/actions/toast';
import { USER_PROFILE_PATH } from '../../../utils/constants';
import { useHistory } from 'react-router-dom';
const SettingTabForm = (props) => {

  
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    participantType: '',
    location: '',
    profilePic:settingImage,
    locationHidden: false,
    currentPassword: '',
    newPassword: '',
    productUpdates:false,
    companyUpdates:false
  });

  const {
    firstName,
    lastName,
    profilePic,
    email,
    participantType,
    location,
    locationHidden,
    currentPassword,
    newPassword,
    companyUpdates,
    productUpdates
    
  } = formData;

  const history=useHistory()
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    props.startLoading();
    const newformData = new FormData();
    newformData.append("file",formData.orignalPic); // appending file 
     let newData={...formData};
     delete newData.orignalPic;
     delete newData.profilePic;   
    newformData.append("data1",JSON.stringify(newData)); // appending file    
      // props.createDog(formData);

    axios_updateProfileData(newformData).then(res=>{
      props.stopLoading();
      if(res.data.success){
        props.loadUser()
        props.setToast(res.data.msg)
        setFormData({
          ...formData,
          newPassword:'',
          currentPassword:''
        })

      }else{
        if(res.data?.errors?.length>0){
          let errors=[]
          let ar_err = res.data?.errors;
            ar_err.map((msg) => {
              return  errors.push({ msg, type: 'error' });
            });
           props.setToast(errors);
        }
      }
    })
  };
  
  useEffect(() => {
    props.startLoading();
    axios_getProfileData().then(res=>{
       
      props.stopLoading();
      if(res.data.success){
        // console.log(res.data)
          setFormData({
            ...formData,
            ...res.data.user,
            locationHidden:res.data.user.locationHidden==='false'?false:true,
            profilePic:res.data.user.profilePic!==""?USER_PROFILE_PATH+res.data.user.profilePic:settingImage
          })
      }
    })
  },[])


  const onAttachmentChange = event => {
    if (event.target.files[0] !== undefined) {
      setFormData({
        ...formData,
        profilePic: URL.createObjectURL(event.target.files[0]),
        orignalPic: event.target.files[0]
      })
      // setValue('profilePic', URL.createObjectURL(event.target.files[0]), {
      //   shouldValidate: true
      // })
    }
  }

  return (
    <div className="SettingTabForm">
      <form onSubmit={onSubmit}>
      <div className="row">
        <div className="col-md-2">
          <div className="settingTabForm-img">
            <button className="settingTabForm-img-btn">
              <img src={profilePic} alt="Profile pic" />
            </button>
            <input
                  type='file'
                  id='profilepic'
                  onChange={onAttachmentChange}
                  accept='image/*'
                />{' '}
                <label htmlFor='profilepic'>
                  <i className='fa fa-edit upload-btn'></i>
                </label>
                

          </div>
        </div>
        <div className="col-md-5 mt-4">
          
            <div className="form-group">
              <input
                type="text"
                placeholder="Negar"
                name="firstName"
                className="form-control"
                value={firstName}
                onChange={(e) => onChange(e)}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                placeholder="Amiri"
                name="lastName"
                className="form-control"
                value={lastName}
                onChange={(e) => onChange(e)}
                required
              />
            </div>

            <div className="form-group">
              <input
                type="email"
                placeholder="pawcker@gmail.com"
                className="form-control"
                name="email"
                readOnly
                value={email}
                onChange={(e) => onChange(e)}
                required
              />
            </div>
            <div className="form-group">
              <select
                className="form-control-select"
                name="participantType"
                value={participantType}
                onChange={(e) => onChange(e)}
                required
              >
                <option value="" >Participant type</option>
                <option value="Contender">Contender</option>
                <option value="Breeder">Breeder</option>
                <option value="Contender+Breeder">Contender + Breeder</option>
              </select>
            </div>

            <div className="form-group">
              <input
                type="text"
                placeholder="Location"
                className="form-control"
                name="location"
                value={location}
                onChange={(e) => onChange(e)}
              />
            </div>

            <div className="checkbox">
              <input
                id="checkbox-input"
                type="checkbox"
                name="locationHidden"
                checked={locationHidden}
                onChange={(e) => {
                    onChange({target:{name:"locationHidden","value":e.target.checked}})
                }}
              />

              <label className="hide" htmlFor="checkbox-input">
                Hide location
              </label>
              
            </div>
        </div>

        <div className="col-md-5">
          <div className="changePassword-form">
            <h6>Change Password</h6>
            <p>Leave blank if you don't want to change</p>
            {/* <form className="change-password-form"> */}
              <div className="form-group">
                <input
                  type="password"
                  placeholder="Current Password"
                  name="currentPassword"
                  className="form-control"
                  autoComplete="off"
                  value={currentPassword}
                  onChange={(e) => onChange(e)}
                />
              </div>
              <div className="form-group">
                <input
                  type="password"
                  placeholder="New Password"
                  name="newPassword"
                  autoComplete="off"
                  className="form-control"
                  value={newPassword}
                  onChange={(e) => onChange(e)}
                />
              </div>
            {/* </form> */}
          </div>

          <div className="changePassword-form">
            <h6>Communication Preferences</h6>
            <p>Opt into receive communications from us</p>
            <p>by checking the box.</p>
            {/* <form className="change-password-form"> */}
              <div className="form-group">
              <div className="checkbox">
              <input
                id="checkbox-input"
                type="checkbox"
                name="productUpdates"
                checked={productUpdates}
                onChange={(e) => {
                    onChange({target:{name:"productUpdates","value":e.target.checked}})
                }}
              />

              <label className="hide" htmlFor="checkbox-input">
                Product Updates
              </label>
              
            </div>
              </div>
              <div className="form-group">
              <div className="checkbox">
              <input
                id="checkbox-input"
                type="checkbox"
                name="companyUpdates"
                checked={companyUpdates}
                onChange={(e) => {
                    onChange({target:{name:"companyUpdates","value":e.target.checked}})
                }}
              />

              <label className="hide" htmlFor="checkbox-input">
                Company Updates
              </label>
              
            </div>
              </div>
            {/* </form> */}
          </div>
          <div  className="btns">
          <input type="submit" className="btn form-control" value="Save" />
          <button className="btn-back" type="button" onClick={()=>history.push('/profile')}>Cancel</button>
          </div>
        </div>
      </div>
      </form>

    </div>
  );
};


const mapStatetoProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
});
export default connect(mapStatetoProps, {setToast,startLoading,stopLoading,loadUser})(SettingTabForm);


