import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const Text = styled(Typography)(({ theme }) => ({
  marginBottom: "1rem",
}));

const PrivacyPolicy = () => {
  return (
    <Box style={{ maxHeight: "70vh", overflow: "auto" }}>
      <Text variant="h4">Purpose</Text>
      <Text>
        The purpose of this privacy policy (this "Privacy Policy") is to inform
        users of our Site of the following: The personal data we will collect;
        Use of collected data; Who has access to the data collected; The rights
        of Site users; and The Site's cookie policy. This Privacy Policy applies
        in addition to the terms and conditions of our Site.
      </Text>
      <Text variant="h4">GDPR</Text>
      <Text>
        For users in the European Union, we adhere to the Regulation (EU)
        2016/679 of the European Parliament and of the Council of 27 April 2016,
        known as the General Data Protection Regulation (the "GDPR"). For users
        in the United Kingdom, we adhere to the GDPR as enshrined in the Data
        Protection Act 2018.
      </Text>
      <Text variant="h4">Consent</Text>
      <Text>
        By using our Site users agree that they consent to the conditions set
        out in this Privacy Policy. When the legal basis for us processing your
        personal data is that you have provided your consent to that processing,
        you may withdraw your consent at any time. If you withdraw your consent,
        it will not make processing which we completed before you withdrew your
        consent unlawful. You can withdraw your consent by emailing
        info@pawcker.com.
      </Text>
      <Text variant="h4">Legal Basis for Processing</Text>
      <Text>
        We collect and process personal data about users in the EU only when we
        have a legal basis for doing so under Article 6 of the GDPR.
      </Text>
      <Text>
        We rely on the following legal bases to collect and process the personal
        data of users in the EU:
      </Text>
      <ul>
        <li>
          Users have provided their consent to the processing of their data for
          one or more specific purposes; and
        </li>
        <li>
          Processing of user personal data is necessary for us or a third pary
          to pursue a legitimate interest. Our legitimate interest is not
          overriden by the interests or fundamenal rights and freedoms of users.
          Our legitimate interest(s) are: Processing their information for their
          created profile.
        </li>
      </ul>
      <Text variant="h4">Personal Data We Collect</Text>
      <Text>
        We only collect data that helps us achieve the purpose set out in this
        Privacy Policy. We will not collect any additional data beyond the data
        listed below without notifying you first.
      </Text>
      <Text>Data Collected Automatically</Text>
      <Text>
        When you visit and use our Site, we may automatically collect and store
        the following information:
      </Text>
      <ul>
        <li>Location; and</li>
        <li>Content viewed.</li>
      </ul>
      <Text>Data Collected in a Non-Automatic Way</Text>
      <Text>
        We may also collect the following data when you perform certain
        functions on our Site:
      </Text>

      <ul>
        <li>First and last name; and</li>
        <li>Email address.</li>
      </ul>

      <Text>This data may be collected when creating an account.</Text>
      <Text variant="h4">How We Use Personal Data</Text>
      <Text>
        Data collected on our Site will only be used for the purposes specified
        in this Privacy Policy or indicated on the relevant pages of our Site.
        We will not use your data beyond what we disclose in this Privacy
        Policy.
      </Text>
      <Text>
        The data we collect automatically is used for the following purposes:
        Analyze website performance and engagement.{" "}
      </Text>
      <Text>
        The data we collect when the user performs certain functions may be used
        for providing services, communication.
      </Text>
      <Text variant="h4">Who We Share Personal Data With</Text>
      <Text>Employees</Text>
      <Text>
        We may disclose user data to any member of our organization who
        reasonably needs access to user data to achieve the purposes set out in
        this Privacy Policy.
      </Text>
      <Text>Other Disclosures</Text>
      <Text>
        We will not sell or share your data with other third parties, except in
        the following cases:
      </Text>
      <ul>
        <li>If the law requires it;</li>
        <li>If it is required for any legal proceeding;</li>
        <li>To prove or protect our legal rights; and</li>
        <li>
          To buyers or potential buyers of this company in the event that we
          seek to sell the company.
        </li>
      </ul>

      <Text>
        If you follow hyperlinks from our Site to another Site, please note that
        we are not responsible for and have no control over their privacy
        policies and practices.
      </Text>
      <Text variant="h4">How Long We Store Personal Data</Text>
      <Text>
        User data will be stored until the purpose the data was collected for
        has been achieved. You will be notified if your data is kept for longer
        than this period.
      </Text>
      <Text variant="h4">How We Protect Your Personal Data</Text>
      <Text>
        We use secure third party services who have a proven record of security
        as their top priority. While we take all reasonable precautions to
        ensure that user data is secure and that users are protected, there
        always remains the risk of harm. The Internet as a whole can be insecure
        at times and therefore we are unable to guarantee the security of user
        data beyond what is reasonably practical.
      </Text>
      <Text variant="h4">Children</Text>
      <Text>
        We do not knowingly collect or use personal data from children under 16
        years of age. If we learn that we have collected personal data from a
        child under 16 years of age, the personal data will be deleted as soon
        as possible. If a child under 16 years of age has provided us with
        personal data their parent or guardian may contact our privacy officer.
      </Text>
      <Text variant="h4">
        How to Access, Modify, Delete, or Challenge the Data Collected
      </Text>
      <Text>
        If you would like to know if we have collected your personal data, how
        we have used your personal data, if we have disclosed your personal data
        and to who we disclosed your personal data, if you would like your data
        to be deleted or modified in any way, or if you would like to exercise
        any of your other rights under the GDPR, please contact our privacy
        officer here: Negar Amiri | negar@pawcker.com | (604) 363-6385
      </Text>
      <Text variant="h4">Do Not Track Notice</Text>
      <Text>
        Do Not Track ("DNT") is a privacy preference that you can set in certain
        web browsers. We do not track the users of our Site over time and across
        third party websites and therefore do not respond to browser-initiated
        DNT signals.
      </Text>
      <Text variant="h4">Cookie Policy</Text>
      <Text>
        A cookie is a small file, stored on a user's hard drive by a website.
        Its purpose is to collect data relating to the user's browsing habits.
        You can choose to be notified each time a cookie is transmitted. You can
        also choose to disable cookies entirely in your internet browser, but
        this may decrease the quality of your user experience.
      </Text>
      <Text>We use the following types of cookies on our Site:</Text>
      <ol>
        <li>
          Functional cookies
          <br />
          Functional cookies are used to remember the selections you make on our
          Site so that your selections are saved for your next visits;
        </li>
        <li>
          Analytical cookies
          <br />
          Analytical cookies allow us to improve the design and functionality of
          our Site by collecting data on how you access our Site, for example
          data on the content you access, how long you stay on our Site, etc;
          and
        </li>
        <li>
          Third-Party Cookies
          <br />
          Third-party cookies are created by a website other than ours. We may
          use third-party cookies to achieve the following purposes:
        </li>
      </ol>
      <Text>Monitor site performance and functionality.</Text>
      <Text variant="h4">Modifications</Text>
      <Text>
        This Privacy Policy may be amended from time to time in order to
        maintain compliance with the law and to reflect any changes to our data
        collection process. When we amend this Privacy Policy we will update the
        "Effective Date" at the top of this Privacy Policy. We recommend that
        our users periodically review our Privacy Policy to ensure that they are
        notified of any updates. If necessary, we may notify users by email of
        changes to this Privacy Policy.
      </Text>
      <Text variant="h4">Complaints</Text>
      <Text>
        If you have any complaints about how we process your personal data,
        please contact us through the contact methods listed in the Contact
        Information section so that we can, where possible, resolve the issue.
        If you feel we have not addressed your concern in a satisfactory manner
        you may contact a supervisory authority. You also have the right to
        directly make a complaint to a supervisory authority. You can lodge a
        complaint with a supervisory authority by contacting the Information
        Commissioner's Office in the UK.
      </Text>
      <Text variant="h4">Contact Information</Text>
      <Text>
        If you have any questions, concerns or complaints, you can contact our
        privacy officer, Negar Amiri, at negar@pawcker.com | (604) 363-6385
      </Text>
    </Box>
  );
};

export default PrivacyPolicy;
