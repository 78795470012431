export const SET_ALERT='SET_ALERT';
export const REMOVE_ALERT='REMOVE_ALERT';

export const SET_TOAST='SET_TOAST';



export const REGISTER_SUCCESS='REGISTER_SUCCESS';
export const REGISTER_FAIL='REGISTER_FAIL';
export const RESET_PASSWORD='RESET_PASSWORD';

export const LOAD_USER='LOAD_USER';
export const AUTH_ERROR='AUTH_ERROR';

export const LOGIN_SUCCESS='LOGIN_SUCESS';
export const LOGIN_ERROR='LOGIN_ERROR';

export const LOGOUT='LOGOUT';



export const GET_PROFILE='GET_PROFILE';


export const GET_SERVICES='GET_SERVICE';

export const GET_ORDERS='GET_ORDERS';


export const GET_PROFILES='GET_PROFILES';
export const GET_REPOS='GET_REPOS';
export const UPDATE_PROFILE='UPDATE_PROFILE';
export const PROFILE_ERROR='PROFILE_ERROR';
export const CLEAR_PROFILE='CLEAR_PROFILE';
export const CREATE_PROFILE='CREATE_PROFILE';
export const ACCOUNT_DELETED='ACCOUNT_DELETED';


export const GET_DOGS='GET_DOGS';
export const CREATE_DOG='CREATE_DOG';



export const START_LOADING='START_LOADING';
export const STOP_LOADING='STOP_LOADING';

export const SET_REQ_LOADING='SET__REQ_LOADING';
