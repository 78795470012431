import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../stylesheets/Forget.css";
import { connect } from "react-redux";
import { startLoading, stopLoading, loginUser } from "../../store/actions/auth";
import {
  axios_confirmCode,
  axios_resetPassword,
  axios_sendCodeToEmail,
} from "../../utils/axios-routes";
import { setToast } from "../../store/actions/toast";
const Forget = (props) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    code: "",
  });

  const [currentStep, setCurrentStep] = useState("enteremail");
  const { email, password, code } = formData;
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    props.startLoading();
    axios_sendCodeToEmail(email?.toLowerCase().trim()).then((res) => {
      props.stopLoading();

      if (res.data.success) {
        setCurrentStep("entercode");
        props.setToast([
          { type: "success", msg: "Code sent successfully to your email" },
        ]);
      } else {
        props.setToast([
          { type: "error", msg: `User not found accociated with ${email}` },
        ]);
      }
    });
  };

  const confirmCode = async (e) => {
    e.preventDefault();
    props.startLoading();
    axios_confirmCode(email?.toLowerCase().trim(), code).then((res) => {
      props.stopLoading();

      if (res.data.success) {
        setCurrentStep("enterpassword");
        props.setToast([
          { type: "success", msg: "Please enter your new password" },
        ]);
      } else {
        props.setToast([{ type: "error", msg: "you entered wrong code" }]);
      }
    });
  };

  const submitPassword = async (e) => {
    e.preventDefault();
    props.startLoading();
    axios_resetPassword(email?.toLowerCase().trim(), password, code).then((res) => {
      props.stopLoading();

      if (res.data.success) {
        props.setToast([
          { type: "success", msg: "Your password is reset successfully" },
        ]);
        setCurrentStep("reseted");
      } else {
        props.setToast([
          { type: "warning", msg: "Password not reset! Somthing went wrong" },
        ]);
      }
    });
  };

  let html = <div></div>;

  if (currentStep === "enteremail") {
    html = (
      <div className="col-md-6">
        {/* <Spinner cls="abs"/> */}

        <div className="Forget-form">
          <h5>Please enter your email</h5>
          <br />
          <form className="form" onSubmit={(e) => onSubmit(e)}>
            <div className="form-group">
              <input
                type="text"
                placeholder="Email Address"
                name="email"
                className="form-control"
                value={email}
                onChange={(e) => onChange(e)}
                required
              />
            </div>
            <div className="form-group">
              <button
                type="submit"
                className="btn form-control"
                disabled={props.loading}
              >
                Send Code
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  } else if (currentStep === "entercode") {
    html = (
      <div className="col-md-6">
        {/* <Spinner cls="abs"/> */}

        <div className="Forget-form">
          <h5>Please enter <code>6-Digit</code> code here</h5>
          <p>Check your email.<br/><small>(Sometimes email goes to the spam folder)</small> </p>
          <br />
          <form className="form" onSubmit={(e) => confirmCode(e)}>
            <div className="form-group">
              <input
                type="text"
                placeholder="eg . 123456"
                name="code"
                className="form-control"
                pattern="[0-9]{6}"
                title="Please enter 6-digit code"
                value={code}
                onChange={(e) => onChange(e)}
                required
              />
            </div>
            <div className="form-group">
              <button
                type="submit"
                className="btn form-control"
                disabled={props.loading}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  } else if (currentStep === "reseted") {
    html = (
      <div className="col-md-6">
        {/* <Spinner cls="abs"/> */}

        <div className="Forget-form">
          <h5>Password is updated successfully.</h5>
          <br />
        </div>
      </div>
    );
  } else if (currentStep === "enterpassword") {
    html = (
      <div className="col-md-6">
        {/* <Spinner cls="abs"/> */}

        <div className="Forget-form">
          <h5>Please Enter new Password</h5>
          <br />
          <form className="form" onSubmit={(e) => submitPassword(e)}>
            <div className="form-group">
              <input
                type="text"
                placeholder=""
                name="password"
                className="form-control"
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,}"
                title="Password contains 8 or more characters and must include one lowercase character, one uppercase character, a number, and a special character."
                value={password}
                onChange={(e) => onChange(e)}
                required
              />
            </div>
            <div className="form-group">
              <button
                type="submit"
                className="btn form-control"
                disabled={props.loading}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }

  return (
    <div className="Forget container">
      <div className="Forget-container">
        <div className="row">
          <h6>Forget Password</h6>
          <div className="col-md-3"></div>

          {html}
        </div>

        <p>
          <Link to="/login">
            <span>Login</span>
          </Link>{" "}
          here
        </p>
      </div>
    </div>
  );
};

const mapStatetoProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
});
export default connect(mapStatetoProps, {
  startLoading,
  stopLoading,
  loginUser,
  setToast,
})(Forget);
