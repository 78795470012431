import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const Text = styled(Typography)(({ theme }) => ({
  marginBottom: "1rem",
}));

const TandC = () => {
  return (
    <Box style={{ maxHeight: "70vh", overflow: "auto" }}>
      <Text variant="h5">Terms and Conditions</Text>
      <Text>
        These terms and conditions (the "Terms and Conditions") govern the use
        of www.pawcker.com (the "Site"). This Site is owned and operated by
        Negar Amiri. This Site is a app.
      </Text>
      <Text>
        By using this Site, you indicate that you have read and understand these
        Terms and Conditions and agree to abide by them at all times.
      </Text>
      <Text variant="h5">Intellectual Property</Text>
      <Text>
        All content published and made available on our Site is the property of
        Negar Amiri and the Site's creators. This includes, but is not limited
        to images, text, logos, documents, downloadable files and anything that
        contributes to the composition of our Site.
      </Text>
      <Text variant="h5">User Contributions</Text>
      <Text>
        Users may post competition results on our Site. By posting publicly on
        our Site, you agree not to act illegally or violate these Terms and
        Conditions.
      </Text>
      <Text variant="h5">Accounts</Text>
      <Text>
        When you create an account on our Site, you agree to the following: You
        are solely responsible for your account and the security and privacy of
        your account, including passwords or sensitive information attached to
        that account; and All personal information you provide to us through
        your account is up to date, accurate, and truthful and that you will
        update your personal information if it changes. We reserve the right to
        suspend or terminate your account if you are using our Site illegally or
        if you violate these Terms and Conditions.
      </Text>
      <Text variant="h5">Consumer Protection Law</Text>
      <Text>
        Where the Business Practice and Consumer Protection Act, or any other
        consumer protection legislation in your jurisdiction applies and cannot
        be excluded, these Terms and Conditions will not limit your legal rights
        and remedies under that legislation. These Terms and Conditions will be
        read subject to the mandatory provisions of that legislation. If there
        is a conflict between these Terms and Conditions and that legislation,
        the mandatory provisions of the legislation will apply.
      </Text>
      <Text variant="h5">Limitation of Liability</Text>
      <Text>
        Negar Amiri and our directors, officers, agents, employees,
        subsidiaries, and affiliates will not be liable for any actions, claims,
        losses, damages, liabilities and expenses including legal fees from your
        use of the Site.
      </Text>
      <Text variant="h5">Indemnity</Text>
      <Text>
        Except where prohibited by law, by using this Site you indemnify and
        hold harmless Negar Amiri and our directors, officers, agents,
        employees, subsidiaries, and affiliates from any actions, claims,
        losses, damages, liabilities and expenses including legal fees arising
        out of your use of our Site or your violation of these Terms and
        Conditions.
      </Text>
      <Text variant="h5">Applicable Law</Text>
      <Text>
        These Terms and Conditions are governed by the laws of the Province of
        British Columbia.
      </Text>
      <Text variant="h5">Severability</Text>
      <Text>
        If at any time any of the provisions set forth in these Terms and
        Conditions are found to be inconsistent or invalid under applicable
        laws, those provisions will be deemed void and will be removed from
        these Terms and Conditions. All other provisions will not be affected by
        the removal and the rest of these Terms and Conditions will still be
        considered valid.
      </Text>
      <Text variant="h5">Changes</Text>
      <Text>
        These Terms and Conditions may be amended from time to time in order to
        maintain compliance with the law and to reflect any changes to the way
        we operate our Site and the way we expect users to behave on our Site.
        We will notify users by email of changes to these Terms and Conditions
        or post a notice on our Site.
      </Text>
      <Text variant="h5">Contact Details</Text>
      <Text>
        Please contact us if you have any questions or concerns. Our contact
        details are as follows: (604) 363-6385 | negar@pawcker.com
      </Text>
    </Box>
  );
};

export default TandC;
