import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { axios_getAllOrganizationsOnlyNames } from "../../../../utils/axios-routes";
import "../../../stylesheets/AddMultiClubs.css";
import AddClubForm from "./AddClubForm";

import { setToast } from "../../../../store/actions/toast";
function checkIfDuplicateExists(arr) {
  return new Set(arr).size !== arr.length;
}
class AddMultiClubs extends React.Component {
  constructor(props) {
    super(props);
    this.state = { orgainzations: [], formData: [], errors: [] };
    this.handleOnChangeInput = this.handleOnChangeInput.bind(this);
    this.handleRemoveClub = this.handleRemoveClub.bind(this);
  }

  handleLoadAllorgainzations = () => {
    axios_getAllOrganizationsOnlyNames().then((res) => {
      if (res.data.success)
        this.setState({ orgainzations: res.data.organizations });
    });
  };

  componentDidMount() {
    this.handleLoadAllorgainzations();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      let errors = [];
      errors = this.props.data.clubs.map((club) => {});
      this.setState({ formData: [...this.props.data.clubs], errors: errors });
    }

    if (this.props.edit) {
      if (prevProps.errors !== this.props.errors) {
        this.setState({ errors: this.props.errors });

        console.log("updating");
      }
    }
  }

  handleOnChangeInput = (index, e) => {
    const tempclubs = [...this.state.formData];
    tempclubs[index][e.target.name] = e.target.value;

    this.setState({ formData: tempclubs });

    this.props.setData({ ...this.props.data, clubs: tempclubs });
  };

  handleRemoveClub = (index) => {
    const tempclubs = [...this.state.formData];
    tempclubs.splice(index, 1);

    console.log(tempclubs);

    this.setState({ formData: tempclubs });

    this.props.setData({ ...this.props.data, clubs: tempclubs });
  };

  handleFormSubmit = (e) => {
    e.preventDefault();
    const errors = [];

    const withoutEmpty = this.props.data.clubs
      .map((club) => club.organization)
      .filter((org) => org !== "");

    console.log(withoutEmpty);
    if (checkIfDuplicateExists(withoutEmpty)) {
      this.props.setToast([
        { type: "warning", msg: "Dublicate clubs not allowed" },
      ]);
      return;
    }

    this.props.data.clubs.map((club) => {
      if (club.organization !== "") {
        let error = {};
        if (club.name === "") {
          error.name = "Name is required";
        }
        if (club.number === "") {
          error.number = "Number is required";
        }

        errors.push(error);
      }
    });

    const actualError = errors.filter((err) => Object.keys(err).length !== 0);
    if (actualError.length > 0) {
      this.setState({ errors: errors });
    } else {
      this.props.handleFormSubmit(e);
      this.setState({ errors: [] });
    }
  };
  render() {
    console.log("current Errors");
    console.log(this.state.errors);
    return (
      <div className="AddMultiClubs">
        <div
          className="club-information"
          style={{ width: this.props?.edit ? "100%" : "" }}
        >
          <div className="row">
            <div className={!this.props.edit ? "col-md-4" : "d-none"}>
              <div className="club-info-img">
                <button>
                  <img src={this.props?.data?.profilePic} alt="" />
                </button>
              </div>
            </div>

            <div className={!this.props.edit ? "col-md-8" : "col-md-12"}>
              <form
                className="club-info-form"
                // onSubmit={handleSubmit(onSubmit)}
                // onReset={reset}
                onSubmit={this.handleFormSubmit}
              >
                <div className="row">
                  <div className="col-md-8 club-info-para">
                    {!this.props.edit && (
                      <div>
                        <h5 className="heading text-left">
                          Add Club Information
                        </h5>
                        <p className="club-info-para-1">
                          Each club or association has their own trials and
                          shows and set of rules. To compete, you'll need to be
                          part of the club or association. To sign up for trials
                          and shows, you'll need your club registration
                          information.
                        </p>
                        <p className="club-info-para-2">
                          The information you input below will not be shown to
                          anyone but you. it's here for you to refer to whenever
                          you need to register for events.
                        </p>
                      </div>
                    )}
                    <button
                      type="button"
                      className="info-btn"
                      onClick={(e) => this.props.onClickInfo()}
                    >
                      <i className="fas fa-plus "></i>
                      {this.props.edit ? "Add Club" : "Info"}
                    </button>

                    <div className="form-group">
                      {this.state.formData?.map((club, index) => {
                        console.log(this.state.errors[index], index);
                        return (
                          <>
                          {index>0&&<div className="club-divider"></div>}
                          <AddClubForm
                            key={index}
                            club={club}
                            index={index}
                            handleOnChangeInput={this.handleOnChangeInput}
                            error={this.state.errors[index]}
                            removeClub={this.handleRemoveClub}
                            orgainzations={this.state.orgainzations}
                          />

                          
                          </>
                        );
                      })}
                    </div>

                    {!this.props.edit && (
                      <div className="row">
                        <div className="col-md-4">
                         
                        </div>
                        <div className="col-md-8 ">
                     
                          <button type="submit" className="club-info-btn">
                            Save
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              this.props.setaddingDog(true);
                              this.props.setaddingClub(false);
                            }}
                            className="club-info-btn-back"
                          >
                            Back
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, { setToast })(AddMultiClubs);
