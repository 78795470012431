import React from "react";

import LoggedInUpcommingCompetitions from "../../components/LoggedInLanding/LoggedInUpcommingCompetitions";
import LoggedInProfile from "../../components/LoggedInLanding/LoggedInProfile";
import { connect } from "react-redux";
import Posts from "../../components/LoggedInLanding/Posts/Posts";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

const LoggedInLanding = () => {
  return (
    <Container maxWidth="100%">
      <Box
        sx={{
          textAlign: "center",
          bgcolor: "primary.backgroundColor",
        }}
      >
        <Grid container>
          <Grid item xs={12} sm={6}>

              <LoggedInProfile />

              <Posts />

          </Grid>
          <Grid item xs={12} sm={6}>
            <LoggedInUpcommingCompetitions />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

const mapStatetoProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
  user: state.auth.user,
});
export default connect(mapStatetoProps, {})(LoggedInLanding);
