import React from "react";
import { Grid, Link, Button, Modal } from "@mui/material";
import { Facebook, Instagram, ShoppingBasket } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import Legal from "./Legal";
import Feedback from "./Feedback";

const FooterContainer = styled(Grid)(({ theme }) => ({
  textAlign: "center",
  color: "white",
  paddingBottom: "1.7rem",
}));

const Footer = () => {
  return (
    <footer>
      <FooterContainer
        container
        spacing={1}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Legal />
        <FooterContainer
        container
        spacing={1}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Feedback />
        <Grid item>
          <Link
            href="https://www.facebook.com/pawcker"
            color="inherit"
            target="_blank"
          >
            <Facebook sx={{ fontSize: 25 }} />
          </Link>
        </Grid>
        <Grid item>
          <Link
            href="https://instagram.com/pawcker"
            color="inherit"
            target="_blank"
          >
            <Instagram sx={{ fontSize: 25 }} />
          </Link>
        </Grid>
        </FooterContainer>
      </FooterContainer>
    </footer>
  );
};

export default Footer;
