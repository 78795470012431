import React from 'react';
import '../../../stylesheets/Dogs.css';
import DogList from './DogList';


const DogRegisteration = ({dogs}) => {
  
  return (
    <div className="Dogs">
      <DogList dogs={dogs} />
    </div>
  );
};

export default DogRegisteration;
