import React from "react";
import { useHistory } from "react-router";

const CDSA = (props) => {
  // console.log(props)
  const history = useHistory();

  console.log(props.levelList);
  return (
    <div className="ACC_Agility row">
      <div className="col-md-6">
        <div className="form-group">
          <select
            onChange={(e) => props?.handleOnChangeInput(e)}
            className="form-control-select"
            name="dog"
            value={props?.fields?.dog}
            placeholder="Dog"
            required
          >
            <option value="" defaultValue disabled>
              Dog
            </option>
            {props?.dogList?.map((d) => {
              return <option value={d._id}>{d.name}</option>;
            })}
          </select>
        </div>

        {props?.fields?.dog !== "" && (
          <div className="form-group">
            <select
              onChange={(e) => props?.handleOnChangeInput(e)}
              name="organization"
              value={props?.fields?.organization}
              className="form-control-select"
              placeholder="Organization"
              required
            >
              <option value="" defaultValue disabled>
                Organization
              </option>
              {props?.orgList?.map((d) => {
                return <option value={d.name}>{d.name}</option>;
              })}
            </select>
          </div>
        )}

        {/* Level */}
        {props?.levelList?.length > 0 ? (
          <div className="form-group">
            <select
              onChange={(e) => props?.handleOnChangeInput(e)}
              name="level"
              value={props?.fields?.level}
              className="form-control-select"
              placeholder="Level"
              required
            >
              <option value="" defaultValue disabled>
                Level
              </option>
              {props?.levelList?.map((lvl) => {
                return <option value={lvl.name}>{lvl.name}</option>;
              })}
            </select>
          </div>
        ) : (
          <div></div>
        )}

        {/* Other fields */}
        {props.fields?.category !== "" && (
          <>
            <div className="form-group">
              <input
                name="judge"
                placeholder="Judge*"
                value={props?.fields?.judge}
                type="text"
                // required={props.fields?.qualified && props.fields?}
                className="form-control-select"
                onChange={props?.handleOnChangeInput}
              />
            </div>

            <div className="form-group">
              <input
                name="date"
                placeholder="date*"
                value={props?.fields?.date}
                type="date"
                className="form-control-select"
                onChange={props?.handleOnChangeInput}
              />
            </div>
            <div className="form-group">
              <input
                name="time"
                placeholder="Time (seconds)*"
                value={props?.fields?.time}
                type="number"
                className="form-control-select"
                onChange={props?.handleOnChangeInput}
              />
            </div>
            {props?.elementList?.length > 0 && props?.fields?.level !== "" ? (
              <div className="form-group">
                <label>Elements Passed</label>
                {props?.elementList?.map((ele) => {
                  return (
                    <div className="form-group">
                      <input
                        name="elementsPassed"
                        id="elementsPassed"
                        placeholder=""
                        checked={props.fields?.elements.includes(ele.name)}
                        type="checkbox"
                        className=""
                        onChange={(e) => {
                          props?.handleOnChangeInput({
                            target: {
                              name: "elementsPassed",
                              value: ele.name,
                            },
                          });
                        }}
                      />
                      <label
                        htmlFor="elementsPassed"
                        style={{
                          fontSize: "17px",
                          marginLeft: "5px",
                          marginTop: "-2px",
                          fontWeight: 600,
                          color: "darkgray",
                        }}
                      >
                        {ele.name}
                      </label>
                    </div>
                  );
                })}
              </div>
            ) : (
              ""
            )}
          </>
        )}
      </div>
      <div
        className="col-md-6 "
        style={{ minHeight: "300px", position: "relative" }}
      >
        {props?.fields?.category !== "" && (
          <>
            <div className="form-group">
              <input
                name="faults"
                placeholder="Faults*"
                value={props?.fields?.faults}
                type="number"
                min="0"
                className="form-control-select"
                onChange={props?.handleOnChangeInput}
              />
            </div>
            <div className="form-group">
              <textarea
                name="qDowell"
                placeholder="What did you do well?*"
                value={props?.fields?.qDowell}
                rows="5"
                type="text"
                className="form-control-select"
                onChange={props?.handleOnChangeInput}
              ></textarea>
            </div>
            <div className="form-group">
              <textarea
                name="qImproveOn"
                placeholder="What do you want to improve?*"
                value={props?.fields?.qImproveOn}
                type="text"
                rows="5"
                className="form-control-select mb-4"
                onChange={props?.handleOnChangeInput}
              ></textarea>
              {/* <hr/> */}
              <button type="submit" className="result-btn ">
                Save
              </button>
            </div>
          </>
        )}
      </div>
      <div className="col-md-12">
        <div></div>
      </div>
      <div className="col-md-6"></div>
      <div className="col-md-6">
        <button
          type="submit"
          className="result-btn-back"
          onClick={() => {
            history.push("/profile/results");
          }}
        >
          Cancel
        </button>

        <p className="mt-4">* Indicates optional field</p>
      </div>
    </div>
  );
};

export default CDSA;
