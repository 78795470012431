import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { axios_getUsernamesOfDogLikes } from "../../../../utils/axios-routes";
import { DOG_PROFILE_PATH } from "../../../../utils/constants";
import { getAge } from "../../../../utils/functions";
import "../../../stylesheets/Dog.css";
import UserNameModal from "../../../UsernameModal";

const Dog = (props) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [usernames, setUsernames] = useState([]);

  const loadUsernames = (id) => {
    handleOpen();

    axios_getUsernamesOfDogLikes(id).then((res) => {
      if (res.data.success) {
        const usernames = res.data.usernames?.map((username) => {
          return {
            ...username,
            username:
              props.user?.username === username.username
                ? `${username.username} (you)`
                : username.username,
          };
        });
        setUsernames(usernames);
      }
    });
  };

  return (
    <div className="Dog">
      <UserNameModal
        open={open}
        handleClose={handleClose}
        usernames={usernames}
      />
      <div className="row">
        <div className="col-12  col-lg-4 p-0">
          <div className="leftInfo">
            <div className="row">
              <div className="col-4 col-md-4">
                <div className="dog-img-div">
                  <button>
                    <img
                      src={DOG_PROFILE_PATH + props.dog.profilePic}
                      alt="Profile Imag"
                      className="dog-image"
                    />
                  </button>
                </div>
              </div>



              <div className="col-8 col-md-8 ">
                <div>
                  <h6 className="name font-weight-bold">{props.dog.name}</h6>
                  <button className="del-btn" onClick={()=>props.handleSelectedDog(props.dog)}>
                    <i className="fa fa-trash"></i>

                  </button>
                  <Link
                    to={"/profile/dogs/edit/" + props.dog._id}
                    className="pull-right edit-btn"
                  >
                    <i className="fa fa-pen" style={{ color: "white" }}></i>{" "}
                    {"Edit"}
                  </Link>

                </div>
              </div>

              <div className="col-12 mt-3">
                <div className="breed-info">
                  {props?.dog?.registrationIds?.map((club) => {
                    return (
                      <p>
                        {club?.organization}
                        {": "} {club?.name} | {club?.number}
                      </p>
                    );
                  })}
                </div>
              </div>

              <div className="col-12 mt-3">
                <div className="like-info">
                  {props?.dog?.likes?.length === 0 && (
                    <>
                      {<i className={`icon fa fa-heart`}></i>}{" "}
                      <p className=" multiname">
                        <strong>{0}</strong> likes
                      </p>
                    </>
                  )}
                  {props?.dog?.likes?.length === 1 && (
                    <>
                      {<i className={`icon fa fa-heart`}></i>}{" "}
                      <p className=" multiname">
                        <Link to={"/user/" + props?.dog?.likes[0].username}>
                          {" "}
                          <strong>{props?.dog?.likes[0].username}</strong>
                        </Link>{" "}
                        like this
                      </p>
                    </>
                  )}
                  {props?.dog?.likes?.length === 2 && (
                    <>
                      {<i className={`icon fa fa-heart`}></i>}{" "}
                      <p className=" multiname">
                        <Link to={"/user/" + props?.dog?.likes[0].username}>
                          {" "}
                          <strong>{props?.dog?.likes[0].username}</strong>
                        </Link>{" "}
                        and
                        <strong>
                          <Link to={"/user/" + props?.dog?.likes[1].username}>
                            {" "}
                            {props?.dog?.likes[1].username}
                          </Link>{" "}
                        </strong>{" "}
                        <br />
                        like this
                      </p>
                    </>
                  )}
                  {props?.dog?.likes?.length > 2 && (
                    <>
                      <i className={`icon fa fa-heart`}></i>{" "}
                      <p className=" multiname">
                        <Link to={"/user/" + props?.dog?.likes[0].username}>
                          {" "}
                          <strong>{props?.dog?.likes[0].username}</strong>
                        </Link>
                        ,
                        <strong>
                          {" "}
                          <Link to={"/user/" + props?.dog?.likes[1].username}>
                            {" "}
                            {props?.dog?.likes[1].username}
                          </Link>
                          ,{" "}
                        </strong>
                        and
                        <br />
                        <button
                          className="btn p-0 text-white"
                          onClick={() => loadUsernames(props.dog._id)}
                        >
                          <strong> {props?.dog?.likes?.length - 2}</strong>
                          {" "} others like this
                        </button>

                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-8 p-0">
          <div className="main-badge-container">
            <div className="count-badge">
              {props?.dog?.medalsLength} <i className="icon fa fa-medal "></i>
            </div>
            {Object.keys(props?.dog?.results).map((club) => {
              return (
                <div className="org-container">
                  {/* <p className="organization">{club}:</p> */}

                  {Object.keys(props?.dog?.results[club]).map((program) => {
                    return (
                      <div className="row">
                        <p className="program mb-3 mt-1">
                          {club}
                          {": "} {program}
                        </p>

                        {props?.dog?.results[club][program].map((badge) => {
                          return (
                            <div className="col-6 col-md-6 col-lg-3 mb-3">
                              <i className="icon fa fa-medal"></i>{" "}
                              <span className="name">{badge}</span>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}


                </div>
              );
            })}

            {Object.keys(props?.dog?.results).length === 0 && <p><strong>No titles earned</strong></p>}
          </div>
        </div>
      </div>

      {/* <div className="hover">
        <button className="btn edit">
          <Link to={"/profile/dogs/edit/" + props.dog._id} className="">
            <i className="fa fa-edit" style={{ color: "white" }}></i>
          </Link>
        </button>
        <br />
        <button
          onClick={() => props.handleRemoveDog(props?.dog?._id)}
          className="btn btn-danger btn-sm mt-1"
        >
          <i className="fa fa-trash"></i>
        </button>
      </div>
      <div className="dog-img-div">
        <button>
        <img
          src={DOG_PROFILE_PATH + props.dog.profilePic}
          alt="Profile Imag"
          className="dog-image"
        />
        </button>
      </div>
      <div className="dog-data ">
        <h6 className="heading font-weight-bold">{props.dog.name}</h6>
        <div className="name-age">
          <p className="name">{props.dog?.breedID?.name} </p> <span>| </span>
          <p className="age"> {getAge(props.dog.dob)}</p>
        </div>
      </div>
      <div className="dog-medal">
        <span style={{ fontSize: "26px", marginTop: "-5px" }}>
          {props?.dog?.medalsLength}
        </span>{" "}
        <i className="fas fa-medal"></i>
      </div> */}
    </div>
  );
};

const mapStatetoProps = (state) => ({
  user: state.auth.users,
});

export default connect(mapStatetoProps, {})(Dog);
