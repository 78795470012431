import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { startLoading, registerUser } from "../../store/actions/auth";

import "../stylesheets/Register.css";

const Register = (props) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    username: "",
    email: "",
    password: "",
  });
  const { firstName, lastName, username, email, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    const data = { ...formData };
    data.email = data.email?.toLowerCase().trim();
    data.username = data.username?.toLowerCase().trim();
    props.registerUser(data);
  };

  return (
    <div className="Register container">
      <div className="Register-container">
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6">
            <div className="signup-form">
              <h6>Sign Up</h6>
              <form className="form" onSubmit={(e) => onSubmit(e)}>
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="First Name"
                    name="firstName"
                    className="form-control"
                    value={firstName}
                    onChange={(e) => onChange(e)}
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Last Name "
                    name="lastName"
                    className="form-control"
                    value={lastName}
                    onChange={(e) => onChange(e)}
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Username"
                    name="username"
                    className="form-control"
                    value={username}
                    onChange={(e) => onChange(e)}
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    placeholder="Email Address"
                    className="form-control"
                    name="email"
                    value={email}
                    onChange={(e) => onChange(e)}
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    placeholder="Password"
                    className="form-control"
                    name="password"
                    minLength="6"
                    value={password}
                    onChange={(e) => onChange(e)}
                  />
                </div>

                <input
                  type="submit"
                  className="btn form-control"
                  value="Register"
                />
              </form>
              <p>
                Already have an account?{" "}
                <Link class="login" to="/login">
                  <span>Log In</span>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStatetoProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
});
export default connect(mapStatetoProps, { startLoading, registerUser })(
  Register
);
