import React, { useEffect } from "react";
import { Button, Modal, Grid, Box } from "@mui/material";
import FeedbackIcon from "@mui/icons-material/Feedback";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  height: "80vh",
  overflow: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const Feedback = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Grid item>
      <Button
        variant="outlined"
        size="small"
        style={{ color: "white", borderColor: "white", marginRight: "2rem" }}
        onClick={handleOpen}
        startIcon={<FeedbackIcon />}
      >
        Feedback
      </Button>{" "}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <iframe src="https://us7.list-manage.com/survey?u=e6c3bfb28e9a484d92e19f3c8&id=f0efa410fb&attribution=false" style={{width: "100%", height: "100%"}}></iframe>
        </Box>
      </Modal>
    </Grid>
  );
};

export default Feedback;
