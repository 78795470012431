import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  loadUser,
  setReqLoading,
  startLoading,
  stopLoading,
} from "../../../../store/actions/auth";
import { setToast } from "../../../../store/actions/toast";
import {
  axios_editResult,
  axios_getAllOrganizationsOnlyNames,
  axios_getFullOrganizationByName,
  axios_getResultByID,
  axios_getUserDogsList,
} from "../../../../utils/axios-routes";
import "../../../stylesheets/EditResult.css";

import ACCAgility from "../FieldsGroup/ACC_Agility";
import BarnHunt from "../FieldsGroup/BarnHunt";
import CWAGSScent from "../FieldsGroup/C-WAGS_Scent";
import CDSA from "../FieldsGroup/CDSA";
import UKCConformation from "../FieldsGroup/UKC_Conformation";
import UKCNosework from "../FieldsGroup/UKC_Nosework";
import UKCWeight from "../FieldsGroup/UKC_Weight";

const EditResult = (props) => {
  const [formData, setFormData] = useState({
    dog: "",
    organization: "",
    program: "",
    level: "",
    judge: "",
    date: "",
    section: "",
    faults: "",
    weight: "",
    vehicle: "",
    placement: "",
    class: "",
    category: "",
    time: "",
    variety: "",
    scents: [],
    qualified: false,
    qDowell: "",
    qImproveOn: "",
    elements: [],
  });
  const [dogList, setDogList] = useState([]);
  const [orgList, setorgList] = useState([]);
  const [programList, setprogramList] = useState([]);
  const [levelList, setlevelList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [elementList, setelementList] = useState([]);
  const [placementList, setPlacementList] = useState([]);
  const [eventList, setEventList] = useState([]); // UKC Conformation
  const [varietyList, setVarietyList] = useState([]); // UKC Conformation
  const [requriedJudge, setrequriedJudge] = useState(false);
  const [classList, setClassList] = useState([]); //  ACC Agility
  const [categoryList, setCategoryList] = useState([]); //  ACC Agility

  // ukc weight

  const [vehicleList, setvehicleList] = useState([]);
  const [weightList, setweightList] = useState([]);

  const params = useParams();
  const history = useHistory();
  const { organization, program } = formData;

  // handling input change
  const handleOnChangeInput = (e) => {
    let data = {};

    // if organization is change load its programs
    if (e.target.name === "organization") {
      props.startLoading();

      // console.log(e.target.value);
      axios_getFullOrganizationByName(e.target.value).then((res) => {
        props.stopLoading();
        setprogramList(res.data.organization.sports);
        setlevelList([]);
        setelementList([]);
        setSectionList([]);
        setPlacementList([]);
        setEventList([]);
        setVarietyList([]);
        setClassList([]);
        setCategoryList([]);
      });

      data = {
        program: "",
        level: "",
        section: "",
        placement: "",
        element: "",
        vareity: "",
        howmany: "",
        manyother: "",
      };
      // if program is change load its levels
    } else if (e.target.name === "program") {
      const program = programList?.map((sport) => {
        if (sport.name === e.target.value) return sport;
      })[0];

      // console.log(program);

      setlevelList(program?.levels);
      setelementList(program?.elements);
      setSectionList(program?.sections);
      setPlacementList(program?.placements);
      // ukc weight
      setweightList(program?.weights);
      setvehicleList(program?.vehicles);

      //UKC conformation
      setEventList(program?.events || []);
      setVarietyList(program?.variety || []);
      setClassList(program?.classes || []);
      setCategoryList(program?.categories || []);
    }

    if (e.target.name === "elementsPassed") {
      setFormData((d) => {
        if (d.elements.includes(e.target.value)) {
          return {
            ...d,
            elements: d.elements.filter((de) => de !== e.target.value),
          };
        } else {
          return {
            ...d,
            elements: [...d.elements, e.target.value],
          };
        }
      });
    } else {
      if (
        e.target.name === "level" &&
        (e.target.value === "Master A" || e.target.value === "Championship")
      ) {
        setrequriedJudge(true);
      } else if (e.target.name === "level") {
        setrequriedJudge(false);
      }

      const temp = {
        ...formData,
        [e.target.name]: e.target.value,
        ...data,
      };

      if (
        e.target.name === "class" &&
        (e.target.value === "Challenge" ||
          e.target.value === "Team relay" ||
          e.target.value === "Steeplechase")
      ) {
        temp.level = "N/A";
      }

      setFormData(temp);
    }
  };

  const getUserDog = () => {
    props.setReqLoading(true);
    axios_getUserDogsList().then((res) => {
      // console.log(res.data.dogs);
      if (res.data.dogs) {
        setDogList(res.data.dogs);
      }
      // props.setReqLoading(false)
    });

    axios_getAllOrganizationsOnlyNames().then((res) => {
      // console.log(res.data);
      props.setReqLoading(true);
      setorgList(res.data.organizations);
      // props.stopLoading();
    });
  };

  const loadResult = () => {
    props.setReqLoading(true);
    axios_getResultByID(params.id).then((res) => {
      // console.log(res.data);

      if (res.data.success) {
        const result = { ...res.data.result };

        if (result.date) {
          result.date = new Date(result.date).toISOString().substr(0, 10);
        }
        setFormData({
          ...formData,
          ...result,
        });

        axios_getFullOrganizationByName(result.organization).then((res) => {
          const program = res.data?.organization?.sports?.filter((sport) => {
            // console.log(sport.name===result?.program);

            if (sport.name === result?.program) return sport;
          })[0];

          // console.log(program);

          setprogramList(res.data?.organization?.sports ?? []);
          setlevelList(program?.levels || []);
          setelementList(program?.elements || []);
          setSectionList(program?.sections || []);
          setPlacementList(program?.placements ?? []);

          // ukc weight
          setweightList(program?.weights || []);
          setvehicleList(program?.vehicles || []);

          setClassList(program?.classes || []);
          setCategoryList(program?.categories || []);
          props.setReqLoading(false);
        });
      }
    });
  };
  useEffect(() => {
    getUserDog();
    loadResult();
  }, []);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    props.startLoading();

    axios_editResult(formData, params.id)
      .then((res) => {
        props.stopLoading();
        if (res.data.success) {
          props.loadUser();
          props.setToast([
            { type: "success", msg: "Result is edit successfully" },
          ]);
          history.push("/profile/results");
        }
      })
      .catch((error) => {
        props.stopLoading();
        props.setToast([{ type: "error", msg: "Internal Server Error" }]);
      });
  };
  let FieldGroup = BarnHunt;

  if (organization === "Barn Hunt Association") FieldGroup = BarnHunt;
  else if (organization === "UKC") {
    if (program === "Nosework") {
      FieldGroup = UKCNosework;
    } else if (program === "Weight Pull") {
      FieldGroup = UKCWeight;
    } else if (program === "Conformation") {
      FieldGroup = UKCConformation;
    }
  } else if (organization === "C-WAGS") {
    if (program === "Scent") {
      FieldGroup = CWAGSScent;
    }
  } else if (organization === "Agility Association of Canada (AAC)") {
    if (program === "Agility") {
      FieldGroup = ACCAgility;
    }
  } else if (organization === "CDSA") {
    FieldGroup = CDSA;
  }

  useEffect(() => {
    if (organization === "CDSA" && programList.length) {
      handleOnChangeInput({ target: { name: "program", value: "Scent" } });
    }
  }, [organization, programList]);

  useEffect(() => {
    if (program && programList?.length) {
      const program1 = programList?.map((sport) => {
        if (sport.name === program) return sport;
      })[0];
      console.log(program1);
      // console.log(program);

      setlevelList(program1?.levels);
      setelementList(program1?.elements);
      setSectionList(program1?.sections);
      setPlacementList(program1?.placements);
      // ukc weight
      setweightList(program1?.weights);
      setvehicleList(program1?.vehicles);

      //UKC conformation
      setEventList(program1?.events || []);
      setVarietyList(program1?.variety || []);
      setClassList(program1?.classes || []);
      setCategoryList(program1?.categories || []);
    }
  }, [program, programList?.length]);

  console.log(program);
  return (
    <div className="edit-result">
      <div className="edit-result-data">
        <form className="edit-result-form" onSubmit={handleFormSubmit}>
          <div className="row">
            <div className="col-md-12">
              <h5 className="heading text-center">Edit Results</h5>
            </div>
            <div className="col-md-12">
              <FieldGroup
                edit
                fields={formData}
                handleOnChangeInput={handleOnChangeInput}
                orgList={orgList}
                dogList={dogList}
                levelList={levelList}
                programList={programList}
                placementList={placementList}
                elementList={elementList}
                sectionList={sectionList}
                setaddingResult={props?.setaddingResult}
                weightList={weightList}
                vehicleList={vehicleList}
                eventList={eventList}
                varietyList={varietyList}
                classList={classList}
                categoryList={categoryList}
                requriedJudge={requriedJudge}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

const mapStatetoProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
});
export default connect(mapStatetoProps, {
  setToast,
  startLoading,
  stopLoading,
  setReqLoading,
  loadUser,
})(EditResult);
