import React from "react";
import { useHistory } from "react-router";

const UKC_Conformation = (props) => {
  const history = useHistory();
  return (
    <div className="UKC_Conformation row">
      <div className="col-md-6">
        <div className="form-group">
          <select
            onChange={(e) => props?.handleOnChangeInput(e)}
            className="form-control-select"
            name="dog"
            value={props?.fields?.dog}
            placeholder="Dog"
            required
          >
            <option value="" defaultValue disabled>
              Dog
            </option>
            {props?.dogList?.map((d) => {
              return <option value={d._id}>{d.name}</option>;
            })}
          </select>
        </div>

        {props?.fields?.dog !== "" ? (
          <div className="form-group">
            <select
              onChange={(e) => props?.handleOnChangeInput(e)}
              name="organization"
              value={props?.fields?.organization}
              className="form-control-select"
              placeholder="Organization"
              required
            >
              <option value="" defaultValue disabled>
                Club
              </option>
              {props?.orgList?.map((d) => {
                return <option value={d.name}>{d.name}</option>;
              })}
            </select>
          </div>
        ) : (
          ""
        )}

        {props?.fields?.organization !== "" ? (
          <div className="form-group">
            <select
              onChange={(e) => props?.handleOnChangeInput(e)}
              name="program"
              value={props?.fields?.program}
              className="form-control-select"
              placeholder="program"
              required
            >
              <option value="" defaultValue disabled>
                Sport
              </option>
              {props?.programList?.map((pro) => {
                return <option value={pro.name}>{pro.name}</option>;
              })}
            </select>
          </div>
        ) : (
          ""
        )}
        {/* {console.log(props?.vehicleList?.length, props?.fields?.program)} */}

        {console.log(props?.fields)}
        {console.log(props?.eventList)}

        {props?.varietyList?.length > 0 && props?.fields?.program !== "" ? (
          <div className="form-group">
            <select
              onChange={(e) => props?.handleOnChangeInput(e)}
              name="variety"
              value={props?.fields?.variety}
              className="form-control-select"
              placeholder="Variety breed"
              required
            >
              <option value="" defaultValue disabled>
                Variety breed
              </option>
              {props?.varietyList?.map((lvl) => {
                return <option value={lvl.name}>{lvl.name}</option>;
              })}
            </select>
          </div>
        ) : (
          <div>yes</div>
        )}

        {props?.eventList && props?.fields?.variety !== "" ? (
          <div className="form-group">
            <select
              onChange={(e) => props?.handleOnChangeInput(e)}
              name="event"
              value={props?.fields?.vehicle}
              className="form-control-select"
              placeholder="Event"
              required
            >
              <option value="" defaultValue disabled>
                Event
              </option>
              {props?.eventList[props?.fields?.variety].map((lvl) => {
                return <option value={lvl.name}>{lvl.name}</option>;
              })}
            </select>
          </div>
        ) : (
          <div></div>
        )}

        {props?.placementList?.length > 0 ? (
          <div className="form-group">
            <select
              onChange={(e) => props?.handleOnChangeInput(e)}
              name="placement"
              value={props?.fields?.placement}
              className="form-control-select"
              placeholder="Placement"
              required
            >
              <option value="" defaultValue disabled>
                Placement
              </option>
              {props?.placementList?.map((plc) => {
                return <option value={plc.name}>{plc.name}</option>;
              })}
            </select>
          </div>
        ) : (
          ""
        )}

        {props.fields?.placement !== "" ? (
          <>
            {props?.fields?.placement === "1st" ? (
              <div>
                <div className="form-group">
                  <input
                    onChange={(e) => props?.handleOnChangeInput(e)}
                    name="howmany"
                    type="number"
                    value={props?.fields?.howmany}
                    className="form-control form-control-select"
                    placeholder=" How many other dogs are competing?"
                    required
                  />
                </div>

                {parseInt(props?.fields?.howmany) > 1 &&
                  (props?.fields?.event === "Grand Champion" ||
                    props?.fields?.event === "Champion") && (
                    <div className="form-group">
                      <input
                        onChange={(e) => props?.handleOnChangeInput(e)}
                        name="manyother"
                        type="number"
                        value={props?.fields?.manyother}
                        className="form-control form-control-select"
                        placeholder="How many other Champions / Grand Champions?"
                        required
                      />
                    </div>
                  )}
              </div>
            ) : (
              ""
            )}

            <div className="form-group">
              <input
                name="date"
                placeholder="date*"
                value={props?.fields?.date}
                type="date"
                className="form-control-select"
                onChange={props?.handleOnChangeInput}
              />
            </div>
           
            <div className="form-group">
              <input
                name="judge"
                placeholder="Judge*"
                value={props?.fields?.judge}
                type="text"
                className="form-control-select"
                onChange={props?.handleOnChangeInput}
              />
            </div>
          </>
        ) : (
          ""
        )}
      </div>
      <div
        className="col-md-6 "
        style={{ minHeight: "300px", position: "relative" }}
      >
        {props?.fields?.placement !== "" ? (
          <>
            <div className="form-group">
              <textarea
                name="qDowell"
                placeholder="What did you do well?*"
                value={props?.fields?.qDowell}
                rows="5"
                type="text"
                className="form-control-select"
                onChange={props?.handleOnChangeInput}
              ></textarea>
            </div>
            <div className="form-group">
              <textarea
                name="qImproveOn"
                placeholder="What do you want to improve?*"
                value={props?.fields?.qImproveOn}
                type="text"
                rows="5"
                className="form-control-select mb-4"
                onChange={props?.handleOnChangeInput}
              ></textarea>
              {/* <hr/> */}
              <button type="submit" className="result-btn ">
                Save
              </button>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
      <div className="col-md-12">
        <div></div>
      </div>
      <div className="col-md-6"></div>
      <div className="col-md-6">
        <button
          type="submit"
          className="result-btn-back"
          onClick={() => {
            history.push("/profile/results");
          }}
        >
          Cancel
        </button>

        <p className="mt-4">* Indicates optional field</p>
      </div>
    </div>
  );
};

export default UKC_Conformation;
