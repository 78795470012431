import React, { useEffect, useState } from 'react';
import '../stylesheets/SearchPage.css';
import SearchItem from '../../components/SearchPage/SearchItem/SearchItem';
import {axios_seearchProfiles } from '../../utils/axios-routes';

const SearchPage = () => {
  const [users, setUsers] = useState([]);
  const [keyword, setKeyword] = useState('');


  const onChange = (e) =>
      setKeyword(e.target.value)
    // setInputItems({ ...inputItems, [e.target.name]: e.target.value });


    useEffect(()=>{
      if(keyword!==""){
        axios_seearchProfiles(keyword).then(res=>{

          console.log(res.data)
          setUsers(res.data.users)
        })
      }
      

    },[keyword])
  
  return (
    <div id="SearchPage">
      <div className="search-box">
        <form className="search">
          <div className="search-input">
            <span className="icon">
              <i className="fas fa-search" aria-hidden="true"></i>
            </span>
            <input
              type="text"
              name="inputItem"
              placeholder="Search by username, name, email"
              onChange={(e) => onChange(e)}
            />
          </div>
        </form>
      </div>

      <div className="searchitems-box">
        <div className="search-item-empty"></div>
      </div>
      <div className="user-list">
        {users&&users.map(user=>{
               return <SearchItem key={user._id} user={user}/>
        })}
      </div>
    </div>
  );
};

export default SearchPage;
