import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { startLoading, loginUser } from "../../store/actions/auth";
import '../stylesheets/Login.css';
import { Typography } from '@mui/material';


const Login = (props) => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const { email, password } = formData;
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    props.loginUser(formData.email?.toLowerCase()?.trim(),formData.password);  
  };



  return (
    <div className="Login container">
      <div className="Login-container">
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6">
            {/* <Spinner cls="abs"/> */}
            <h6>Log In</h6>
            <div className="login-form">
              <form className="form" onSubmit={(e) => onSubmit(e)}>
                <div className="form-group" style={{paddingBottom: '0.8rem'}}>
                  <input
                    type="text"
                    placeholder="Email Address"
                    name="email"
                    className="form-control"
                    value={email}
                    onChange={(e) => onChange(e)}
                    required
                    style={{marginBottom: '-0.05rem'}}
                  />
                  <Typography variant="caption" style={{fontSize: '0.7rem'}}>If your email address is not accepted, try your username.</Typography>
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    placeholder="Password"
                    className="form-control"
                    name="password"
                    minLength="6"
                    value={password}
                    onChange={(e) => onChange(e)}
                  />
                  <br />
                  <button
                    type="submit"
                    className="btn form-control"
                    disabled={props.loading}
                  >Login</button>
                  
                </div>
              </form>
            </div>
          </div>
        </div>
<br/>
        <Link to="/forget-password">Forgot your password ?</Link>
        <p>
          Don't have an account?{' '}
          <Link to="/register">
            <span>Sign Up</span>
          </Link>
        </p>
      </div>
    </div>
  );
};


const mapStatetoProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading
});
export default connect(mapStatetoProps, { startLoading, loginUser })(Login);
