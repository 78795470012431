import axios from "../axios-server";

export const axios_loginUser = (email, password) => {
  return axios.post(
    "/auth/signin",
    { email, password },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const axios_registerUser = (data) => {
  return axios.post("/user/signup", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const axios_loadUser = () => {
  return axios.post(
    "/auth/userData",
    {},
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const axios_sendCodeToEmail = (email) => {
  return axios.post(
    "/auth/sendcode",
    { email },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const axios_confirmCode = (email, code) => {
  return axios.post(
    "/auth/confirmcode",
    { email, code },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const axios_resetPassword = (email, password, code) => {
  return axios.post(
    "/auth/resetpassword",
    { email, code, password },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

// Dog Routes

export const axios_createClub = (data) => {
  return axios.post("/club/add-club", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const axios_getallClubs = () => {
  return axios.get("/club/getallclubs", {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const axios_getSportByClub = (clubId) => {
  return axios.get("/club/" + clubId + "/sport", {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const axios_createDog = (data) => {
  return axios.post("/dog/add-dog", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const axios_editDog = (data, id) => {
  return axios.put("/dog/edit-dog/" + id, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const axios_deleteDog = (id) => {
  return axios.delete("/dog/delete-dog/" + id);
};

export const axios_checkDogAllowed = () => {
  return axios.get("/dog/check-allowed");
};

export const axios_getUserDogs = () => {
  return axios.get("/dog/get-user-dogs", {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const axios_getUserDogsList = () => {
  return axios.get("/dog/get-user-dogs-list", {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const axios_getDogByID = (id) => {
  return axios.get("/dog/id/" + id, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const axios_getAllBreads = () => {
  return axios.get("/breed", {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const axios_getFilteredEvents = (query) => {
  let queryString = "?";

     query.fromNow=new Date().toLocaleDateString("sv-SE");
  console.log(query.fromNow);
  Object.keys(query).map((key) => {
    if (query[key] !== "")
      queryString += key + "=" + query[key] + "&";
    return key;
  });


  
  return axios.get("/event" + queryString, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const axios_getAllOrganizationsOnlyNames = () => {
  return axios.get("/organization/getallorganizationNames", {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const axios_getAllOrganizations = () => {
  return axios.get("/organization/getallorganization", {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const axios_getFullOrganizationByName = (name) => {
  // console.log("ddddddddddddddd",id)
  return axios.get(`/organization/${name}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};



export const axios_getOrganizationPrograms = (oid) => {
  // console.log("ddddddddddddddd",id)
  return axios.get(`/organization/${oid}/programs`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const axios_getOrganizationProgramLevels = (orgid, pid) => {
  return axios.get(`/organization/${orgid}/program/${pid}/levels`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const axios_getProfileData = () => {
  return axios.get("/user/profile-data", {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const axios_getUserFollowers = () => {
  return axios.get("/user/profile/followers", {
    headers: {
      "Content-Type": "application/json",
    },
  });
};


export const axios_getUserFollowing = () => {
  return axios.get("/user/profile/following", {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const axios_updateProfileData = (data) => {
  return axios.put("/user/profile-data", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const axios_seearchProfiles = (keyword) => {
  return axios.get(
    `/user/search-profiles?keyword=${keyword}`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const axios_getNotifications = () => {
  return axios.get(`/user/home/notifications`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const axios_createResult = (data) => {
  return axios.post(`/result/create`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const axios_getUsernamesOfResultLikes = (id) => {
  return axios.get(`result/${id}/like/usernames`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};


export const axios_getUsernamesOfDogLikes = (id) => {
  return axios.get(`dog/${id}/like/usernames`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};






export const axios_editResult = (data, id) => {
  return axios.put("/result/edit-result/" + id, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};



export const axios_cloneResult = (data, id) => {
  return axios.post("/result/clone/"+id,{}, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const axios_deleteResult = (id) => {
  return axios.delete("/result/delete-result/" + id);
};

export const axios_getPendingResult = () => {
  return axios.get("/result/pending");
};
export const axios_updatePendingBadgeToAdd = (id) => {
  return axios.put("/result/pending/" + id + "/show");
};

export const axios_getResultByID = (id) => {
  return axios.get("/result/id/" + id, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const axios_getUserResults = () => {
  return axios.get(`/result/user-results`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const axios_getUserProfileByUsername = (username) => {
  return axios.get(`/user/${username}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const axios_followOrUnfollowUser = (userId) => {
  return axios.put(`/user/follow-or-unfollow/${userId}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const axios_likeOrUnlikeNotification = (type, postID) => {
  return axios.put(`/user/notification/${type}/${postID}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
