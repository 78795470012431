import React from "react";

import { connect } from "react-redux";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { USER_PROFILE_PATH } from "../../utils/constants";

const Profile = (props) => {
  return (
    <Box
      sx={{
        width: "100%",
        bgcolor: "secondary.main",
        padding: "1rem",
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={6} sm={3}>
          <Avatar
            alt="Profile Pic"
            src={props.user && USER_PROFILE_PATH + props.user.profilePic}
            sx={{ width: "6rem", height: "6rem" }}
          />
        </Grid>
        <Grid item xs={6} sm={9} textAlign="left">
          <Typography variant="h6" color="white">
            Welcome back {props.user && props.user.firstName}{" "}
            {props.user && props.user.lastName}
          </Typography>
          <Typography variant="subtitle 2" color="white">
            @{props.user && props.user.username}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

const mapStatetoProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
  user: state.auth.user,
});
export default connect(mapStatetoProps, {})(Profile);
