import React from 'react';

import '../stylesheets/ProfilePage.css';

import Profile from '../../components/ProfilePage/Profile';
import ProfileBottom from '../../components/ProfilePage/ProfileBottom';

const ProfilePage = () => {
  return (
    <div className="ProfilePage">
      <div className="Profile-parent">
        <Profile />
      </div>
      <ProfileBottom />
    </div>
  );
};

export default ProfilePage;
