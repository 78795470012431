import React, { useState, useEffect } from "react";
import "../../../stylesheets/EditDog.css";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  axios_editDog,
  axios_getAllBreads,
  axios_getAllOrganizationsOnlyNames,
  axios_getDogByID,
} from "../../../../utils/axios-routes";
import { useHistory, useParams } from "react-router-dom";
import { DOG_PROFILE_PATH } from "../../../../utils/constants";
import { setToast } from "../../../../store/actions/toast";
import { connect } from "react-redux";
import { startLoading, stopLoading } from "../../../../store/actions/auth";
import AddMultiClubs from "../AddMultiClubs";
import { sortData } from "../../../../utils/functions";
function checkIfDuplicateExists(arr) {
  return new Set(arr).size !== arr.length;
}

const initialData = {
  organization: "",
  name: "",
  number: "",
};
const EditDog = (props) => {
  const params = useParams();

  const validationSchema = Yup.object().shape({
    breed: Yup.object().shape({
      value: Yup.string().required("Breed is required"),
    }),
    callName: Yup.string().required("Call Name is required"),
    owner: Yup.string().required("Owner is required"),
    dob: Yup.string()
      .required("Date of Birth is required")
      .matches(
        /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
        "Date of Birth must be a valid date in the format YYYY-MM-DD"
      ),
  });

  const [breeds, setbreeds] = useState([]);
  const [orgainzations, setorgainzations] = useState([]);
  const history = useHistory();
  const [pic, setPic] = useState({
    profilePic: DOG_PROFILE_PATH + "dummy-profile.png",
  });
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const [formData, setFormData] = useState({
    organization: "",
    name: "",
    number: "",
  });
  const [errorsClub, setErrorsClub] = useState([]);

  const { organization, name, number } = formData;

  const handleOnChangeInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    axios_getAllBreads().then((res) => {
      if (res.data.success) {
        let options = [];
        const data = sortData(res.data.breeds);

        data.map((org) => options.push({ label: org.name, value: org._id }));
        setbreeds(options);
      }
    });

    axios_getAllOrganizationsOnlyNames().then((res) => {
      if (res.data.success) setorgainzations(res.data.organizations);
    });
  }, []);

  function onSubmit(data) {
    const err = {};

    console.log(data);

    const errors = [];

    const withoutEmpty = formData.clubs
      .map((club) => club.organization)
      .filter((org) => org !== "");

    console.log(withoutEmpty);
    if (checkIfDuplicateExists(withoutEmpty)) {
      props.setToast([{ type: "warning", msg: "Dublicate clubs not allowed" }]);
      return;
    }

    formData.clubs.map((club) => {
      if (club.organization !== "") {
        let error = {};
        if (club.name === "") {
          error.name = "Name is required";
        }
        if (club.number === "") {
          error.number = "Number is required";
        }

        errors.push(error);
      }
    });

    const actualError = errors.filter((err) => Object.keys(err).length !== 0);
    if (actualError.length > 0) {
      setErrorsClub(errors);
    } else {
      const newData = {
        ...data,
        clubs: [...formData.clubs],
        ...pic,
      };

      const formDataX = new FormData();
      if (pic.orignalPic) {
        formDataX.append("file", pic.orignalPic); // appending file
      }
      delete newData.orignalPic;
      delete newData.profilePic;
      formDataX.append("data1", JSON.stringify(newData)); // appending file

      props.startLoading();
      axios_editDog(formDataX, params.id).then((res) => {
        props.stopLoading();
        if (res.data.success) {
          props.setToast([
            { type: "success", msg: "Dog updated successfully" },
          ]);

          setTimeout(() => {
            history.push("/profile/dogs");
          }, 2000);
        }
      });

      setErrorsClub([]);
    }
  }

  useEffect(() => {
    // setTimeout(()=>{
    const id = params.id;

    axios_getDogByID(id).then((res) => {
      if (res.data.success) {
        const dog = res.data.dog;
        setValue("callName", dog.name);
        onBreedChange({ label: dog.breedID?.name, value: dog.breedID?._id });
        setPic({ profilePic: DOG_PROFILE_PATH + dog.profilePic });
        setValue("owner", dog.owner);
        setValue("breedType", dog.breedType);
        setValue("height", dog.height);
        setValue("weight", dog.weight);
        setValue("dob", new Date(dog.dob).toISOString().substr(0, 10));

        if (dog.registrationIds) {
          setFormData({
            ...formData,
            clubs: dog.registrationIds,
            breed:{label: dog.breedID?.name, value: dog.breedID?._id }
          });
        }
      }
    });

  }, [breeds]);

  const onBreedChange = (e) => {
    console.log("e", e);
    setValue("breed", e, { shouldValidate: true });

    if(e.label!=="Mixed Breed"){
      setValue('breedType', '' )
  
      }
  };

  const onAttachmentChange = (event) => {
    if (event.target.files[0] !== undefined) {
      setPic({
        profilePic: URL.createObjectURL(event.target.files[0]),
        orignalPic: event.target.files[0],
      });
    }
  };

  const onClickInfo = () => {
    const tempClubs = [...formData.clubs];
    console.log("test");
    console.log(tempClubs);

    tempClubs.push({ ...initialData });
    console.log(tempClubs);
    setFormData({ ...formData, clubs: tempClubs });
  };

  return (
    <div className="EditDog">
      <div className="add-dog">
        <form
          className="add-dog-form"
          onSubmit={handleSubmit(onSubmit)}
          onReset={reset}
        >
          <div className="row">
            <div className="col-md-3">
              <div className="add-dog-img">
                <button>
                  <img src={pic.profilePic} alt="dog profile" />
                </button>
                <input
                  type="file"
                  id="profilepic"
                  onChange={onAttachmentChange}
                  accept="image/*"
                />{" "}
                <label htmlFor="profilepic">
                  <i className="fa fa-edit upload-btn"></i>
                </label>
              </div>
              <div className="invalid-feedback" style={{ display: "block" }}>
                {errors.profilePic?.message}
              </div>
            </div>
            <div className="col-md-6">
              <h5 className="heading">Edit Dog</h5>

              <div className="row">
                <div className="col-md-4">
                  <p className="text-left mb-0 mt-2"> Breed</p>
                </div>
                <div className="col-md-8 mb-2">
                  <div className="form-group">
                    <Select
                      options={breeds}
                      name="breed"
                      value={getValues("breed")}
                      {...register("breed")}
                      onChange={onBreedChange}
                      placeholder="Breed"
                      required={true}
                      className={`${errors.breed ? "is-invalid" : ""} select`}
                    />
                    <div className="invalid-feedback">
                      {errors.breed?.value?.message}
                    </div>
                  </div>
                </div>

                {formData?.breed?.label === "Mixed Breed" && (
                  <>
                    <div className="col-md-4">
                      <p className="text-left mb-0 mt-2">Breed Type</p>
                    </div>
                    <div className="col-md-8">
                      <input
                        name="breedType"
                        {...register("breedType")}
                        className={`form-control ${
                          errors.callName ? "is-invalid" : ""
                        }`}
                        type="text"
                        placeholder=""
                      />
                      <div className="invalid-feedback">
                        {errors.breedType?.message}
                      </div>
                    </div>
                  </>
                )}

                <div className="col-md-4">
                  <p className="text-left mb-0 mt-2"> Owner Name</p>
                </div>
                <div className="col-md-8">
                  {" "}
                  <input
                    {...register("owner")}
                    className={`form-control ${
                      errors.owner ? "is-invalid" : ""
                    }`}
                    name="owner"
                    type="text"
                    placeholder=""
                  />
                  <div className="invalid-feedback">
                    {errors.owner?.message}
                  </div>
                </div>

                <div className="col-md-4">
                  <p className="text-left mb-0 mt-2"> Date of Birth</p>
                </div>
                <div className="col-md-8">
                  {" "}
                  <input
                    {...register("dob")}
                    className={`form-control ${errors.dob ? "is-invalid" : ""}`}
                    name="dob"
                    type="date"
                    max={new Date().toISOString().split("T")[0]}
                    placeholder="DOB"
                  />
                  <div className="invalid-feedback">{errors.dob?.message}</div>
                </div>

                <div className="col-md-4">
                  <p className="text-left mb-0 mt-2"> Height (inches)*</p>
                </div>
                <div className="col-md-8">
                  {" "}
                  <input
                    {...register("height")}
                    className={`form-control ${
                      errors.height ? "is-invalid" : ""
                    }`}
                    name="height"
                    type="number"
                    placeholder=""
                  />
                  <div className="invalid-feedback">
                    {errors.height?.message}
                  </div>
                </div>

                <div className="col-md-4">
                  <p className="text-left mb-0 mt-2"> Weight (kg)*</p>
                </div>
                <div className="col-md-8">
                  {" "}
                  <input
                    {...register("weight")}
                    className={`form-control ${
                      errors.weight ? "is-invalid" : ""
                    }`}
                    name="weight"
                    type="number"
                    placeholder=""
                  />
                  <div className="invalid-feedback">
                    {errors.weight?.message}
                  </div>{" "}
                </div>
              </div>

              <h5 className="heading pt-4 mb-0">Edit Club</h5>

              <AddMultiClubs
                data={formData}
                handleFormSubmit={null}
                setaddingClub={true}
                setaddingDog={false}
                setData={setFormData}
                showForm={true}
                onClickInfo={onClickInfo}
                edit={true}
                // handleFormSubmit={handleSubmit}
                errors={errorsClub}
              />
            </div>
            <div className="col-md-9"></div>

            <div className="col-md-3 btn-container">
              <button
                type="button"
                onClick={() => {
                  history.push("/profile/dogs");
                }}
                className="btn close-dog-btn"
              >
                Cancel
              </button>
              <button type="submit" className="btn add-dog-btn">
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
const mapStatetoProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
  dogs: state.dogs.dogs,
});

export default connect(mapStatetoProps, {
  setToast,
  startLoading,
  stopLoading,
})(EditDog);
