import * as atypes from "./types";
// import {v4 as uuid} from 'uuid'


// setAlert("system error",'danger');

export const setToast = (toasts,options={}) => dispath => {
  // const id = uuid();

  const data={
    type: atypes.SET_TOAST,
    payload:[...toasts]
    
  };

  dispath(data);


  // setTimeout(()=>dispath({ type: atypes.REMOVE_ALERT, payload:{"id":id}})
  //  , timeout);


   
};
