import React, { useEffect, useState } from "react";
import { DOG_PROFILE_PATH } from "../../../utils/constants";
import { axios_likeOrUnlikeNotification } from "../../../utils/axios-routes";
import {
  Typography,
  Link,
  IconButton,
  Grid,
  Paper,
  Avatar,
} from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";

const ResultPost = ({ post}) => {

  const [liked, setLiked] = useState(post.liked);
  const [likedC, setLikedC] = useState(post.likes.length);

  useEffect(() => {
    setLiked(post.liked);
  }, [post.liked]);

  const handleLikeClick = () => {
    axios_likeOrUnlikeNotification("result", post?._id).then((res) => {
      if (res.data.like) {
        setLikedC(likedC + 1);
      } else {
        setLikedC(likedC - 1);
      }

      setLiked(res.data.like);
    });
  };

  const profilePic = DOG_PROFILE_PATH + post?.dog?.profilePic;
  const header = "added a new result";
  const subtitle = `${post?.dog?.name} | ${post.organization} | ${post.program} ${post.level}`;
  return (
    <Paper style={{ backgroundColor: "rgba(36, 37, 130, 0.3)", marginTop: '1.5rem' }}>
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        style={{
          textAlign: "left",
          paddingLeft: "1rem",
          paddingBottom: "0.8rem",
        }}
      >
        <Grid item xs={2}>
        {profilePic.includes("2021-11-11") ? (
            <Avatar sx={{ width: "4.5rem", height: "4.5rem" }}>
              {post?.dog?.name.charAt(0).toUpperCase()}
            </Avatar>
          ) : (
            <Avatar
              alt="Profile Picture"
              src={profilePic}
              sx={{ width: "4.5rem", height: "4.5rem" }}
            />
          )}
        </Grid>
        <Grid item xs={8} style={{ paddingLeft: "2rem" }}>
          <Typography component="div" variant="subtitle1">
            <Link
              href={"/user/" + post?.userId.username}
              style={{ color: "inherit", textDecoration: "none" }}
            >
              @{post?.userId?.username}
            </Link>{" "}
            {header}
          </Typography>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            component="div"
          >
            {subtitle}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <IconButton aria-label="like post" onClick={handleLikeClick}>
            <FavoriteIcon color={liked ? "primary" : " "} />{" "}
            <Typography variant="h6">{likedC}</Typography>
          </IconButton>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ResultPost;
