
// Constants.js
const envs = {
  live:{
      API_URL: 'https://d387rniyq0k8oj.cloudfront.net/' //temp S3 URL
    //   API_URL: 'https://api.pawcker.com' //Actual server URL
  },
  dev: {
      API_URL: 'https://pawcker-api.herokuapp.com',
  },
  local: {
      API_URL: 'http://localhost:5000',
    }
}

export const config =envs[process.env.REACT_APP_APP_ENV];