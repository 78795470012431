import React, { useEffect, useState } from 'react';
import '../../stylesheets/Followers.css';
import { connect } from 'react-redux';
import { axios_getUserFollowers } from '../../../utils/axios-routes';
import SearchItem from '../../SearchPage/SearchItem/SearchItem';
const Followers = (props) => {

  const [followers, setFollowers] = useState([])
  useEffect(() => {
    axios_getUserFollowers().then(res => {
      setFollowers(res.data.users)
    })
  }, [])

  return (
    <div className='Followers'>
      {followers?.length > 0 ? (
        followers.map((follower, index) => {
          return (
            <div className='mt-3' key={index}>
              {/* <Follower follower={follower} /> */}
              <SearchItem user={follower} />
            </div>
          )
        })
      ) : (
        <p className='mt-3 font-weight-bold light-color'>
          You have no follower yet
        </p>
      )}
    </div>
  )
}

const mapStatetoProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading
})
export default connect(mapStatetoProps, {})(Followers)
