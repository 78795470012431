import React from "react";
import { useHistory } from "react-router";

const UKC_Weight = (props) => {
  const history = useHistory();
  return (
    <div className="UKC_Weight row">
      <div className="col-md-6">
        <div className="form-group">
          <select
            onChange={(e) => props?.handleOnChangeInput(e)}
            className="form-control-select"
            name="dog"
            value={props?.fields?.dog}
            placeholder="Dog"
            required
          >
            <option value="" defaultValue disabled>
              Dog
            </option>
            {props?.dogList?.map((d) => {
              return <option value={d._id}>{d.name}</option>;
            })}
          </select>
        </div>

        {props?.fields?.dog !== "" ? (
          <div className="form-group">
            <select
              onChange={(e) => props?.handleOnChangeInput(e)}
              name="organization"
              value={props?.fields?.organization}
              className="form-control-select"
              placeholder="Organization"
              required
            >
              <option value="" defaultValue disabled>
                Club
              </option>
              {props?.orgList?.map((d) => {
                return <option value={d.name}>{d.name}</option>;
              })}
            </select>
          </div>
        ) : (
          ""
        )}

        {props?.fields?.organization !== "" ? (
          <div className="form-group">
            <select
              onChange={(e) => props?.handleOnChangeInput(e)}
              name="program"
              value={props?.fields?.program}
              className="form-control-select"
              placeholder="program"
              required
            >
              <option value="" defaultValue disabled>
                Sport
              </option>
              {props?.programList?.map((pro) => {
                return <option value={pro.name}>{pro.name}</option>;
              })}
            </select>
          </div>
        ) : (
          ""
        )}
        {/* {console.log(props?.vehicleList?.length, props?.fields?.program)} */}

        {props?.vehicleList?.length > 0 && props?.fields?.program !== "" ? (
          <div className="form-group">
            <select
              onChange={(e) => props?.handleOnChangeInput(e)}
              name="vehicle"
              value={props?.fields?.vehicle}
              className="form-control-select"
              placeholder="Level"
              required
            >
              <option value="" defaultValue disabled>
                Vehicle
              </option>
              {props?.vehicleList && props?.vehicleList?.map((lvl) => {
                return <option value={lvl.name}>{lvl.name === "Wheels" ? "Wheels on natural and artificial surface / vehicles" : lvl.name}</option>;
              })}
            </select>
          </div>
        ) : (
          <div></div>
        )}
        {props?.vehicleList && <>{props.fields?.vehicle === props?.vehicleList[2]?.name && (
          <div className="form-group">
            <input
              name="surface"
              placeholder="Surface"
              value={props?.fields?.surface}
              type="text"
              className="form-control-select"

              onChange={props?.handleOnChangeInput}
            />
          </div>
        )}</>}


        {props?.weightList?.length > 0 && props?.fields?.vehicle !== "" ? (
          <div className="form-group">
            <select
              onChange={(e) => props?.handleOnChangeInput(e)}
              name="weight"
              value={props?.fields?.weight}
              className="form-control-select"
              placeholder="Class Weight"
              required
            >
              <option value="" defaultValue disabled>
                Class Weight
              </option>
              {props?.weightList?.map((ele) => {
                return <option value={ele.name}>{ele.name}</option>;
              })}
            </select>
          </div>
        ) : (
          ""
        )}

        {props?.sectionList?.length > 0 && props?.fields?.vehicle ? (
          <div className="form-group">
            <select
              onChange={(e) => props?.handleOnChangeInput(e)}
              name="section"
              value={props?.fields?.section}
              className="form-control-select"
              placeholder="Section"
              required
            >
              <option value="" defaultValue disabled>
                Section
              </option>
              {props?.sectionList?.map((sec) => {
                return <option value={sec.name}>{sec.name}</option>;
              })}
            </select>
          </div>
        ) : (
          ""
        )}
        {props?.placementList?.length > 0 && props?.fields?.section !== "" ? (
          <div className="form-group">
            <select
              onChange={(e) => props?.handleOnChangeInput(e)}
              name="placement"
              value={props?.fields?.placement}
              className="form-control-select"
              placeholder="Placement"
              required
            >
              <option value="" defaultValue disabled>
                Placement
              </option>
              {props?.placementList?.map((plc) => {
                return <option value={plc.name}>{plc.name}</option>;
              })}
            </select>
          </div>
        ) : (
          ""
        )}

        {props.fields?.section !== "" ? (
          <>
            <div className="form-group">
              <input
                name="pulled"
                placeholder="Weight pulled (lbs)"
                value={props?.fields?.pulled}
                type="number"
                min="0"
                className="form-control-select"
                onChange={props?.handleOnChangeInput}
              />
            </div>
            <div className="form-group">
              <input
                name="points"
                placeholder="Points"
                value={props?.fields?.points}
                type="number"
                min="0"
                className="form-control-select"
                onChange={props?.handleOnChangeInput}
              />
            </div>

            <div className="form-group">
              <input
                name="date"
                placeholder="date*"
                value={props?.fields?.date}
                type="date"
                className="form-control-select"
                onChange={props?.handleOnChangeInput}
              />
            </div>
            <div className="form-group">
              <input
                name="time"
                placeholder="Time (seconds)*"
                value={props?.fields?.time}
                type="number"
                className="form-control-select"
                onChange={props?.handleOnChangeInput}
              />
            </div>
            <div className="form-group">
              <input
                name="judge"
                placeholder="Judge*"
                value={props?.fields?.judge}
                type="text"
                className="form-control-select"
                onChange={props?.handleOnChangeInput}
              />
            </div>
          </>
        ) : (
          ""
        )}
      </div>
      <div
        className="col-md-6 "
        style={{ minHeight: "300px", position: "relative" }}
      >
        {props?.fields?.section !== "" ? (
          <>
            <div className="form-group">
              <textarea
                name="qDowell"
                placeholder="What did you do well?*"
                value={props?.fields?.qDowell}
                rows="5"
                type="text"
                className="form-control-select"
                onChange={props?.handleOnChangeInput}
              ></textarea>
            </div>
            <div className="form-group">
              <textarea
                name="qImproveOn"
                placeholder="What do you want to improve?*"
                value={props?.fields?.qImproveOn}
                type="text"
                rows="5"
                className="form-control-select mb-4"
                onChange={props?.handleOnChangeInput}
              ></textarea>
              {/* <hr/> */}
              <button type="submit" className="result-btn ">
                Save
              </button>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
      <div className="col-md-12">
        <div></div>
      </div>
      <div className="col-md-6"></div>
      <div className="col-md-6">
        <button
          type="submit"
          className="result-btn-back"
          onClick={() => {
            history.push("/profile/results");
          }}
        >
          Cancel
        </button>

        <p className="mt-4">* Indicates optional field</p>
      </div>
    </div>
  );
};

export default UKC_Weight;
