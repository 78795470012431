import React, { useState } from "react";
import {
  DOG_TAB,
  EVENT_TAB,
  FOLLOWERS_TAB,
  FOLLOWING_TAB,
} from "../../../utils/constants";
import "../../stylesheets/ProfileBottom.css";

import Dogs from "./Dogs/Dogs";
import Followers from "./Followers/Followers";
import Following from "./Followers/Following";
import ResultList from "./ResultList";

const ProfileBottom = ({ user }) => {
  const [currentTab, setCurrentTab] = useState(DOG_TAB);

  const handleChangeTab = (tab) => {
    setCurrentTab(tab);
  };

  let tabHtml = <Dogs dogs={user?.dogs} />;
  if (currentTab === EVENT_TAB) {
    tabHtml = <ResultList results={user?.results} />;
  } else if (currentTab === FOLLOWERS_TAB) {
    tabHtml = <Followers users={user.followers} />;
  } else if (currentTab === FOLLOWING_TAB) {
    tabHtml = <Following users={user.following||[]} />;
  }
  return (
    <div className="ProfileBottom">
      <div className="container" style={{ padding: "0px" }}>
        <div className="tabs">
          <div className="tab-buttons">
            <button
              className={[
                "dog-btn tab-btn",
                currentTab === DOG_TAB ? "active" : "",
              ].join(" ")}
              onClick={() => handleChangeTab(DOG_TAB)}
            >
              Dogs
            </button>
            <button
              className={[
                "event-btn tab-btn",
                currentTab === EVENT_TAB ? "active" : "",
              ].join(" ")}
              onClick={() => handleChangeTab(EVENT_TAB)}
            >
              Results
            </button>
            <button
              className={[
                "follower-btn tab-btn",
                currentTab === FOLLOWERS_TAB ? "active" : "",
              ].join(" ")}
              onClick={() => handleChangeTab(FOLLOWERS_TAB)}
            >
              Followers ({user.followers?.length||0})
            </button>
            <button
              className={[
                "following-btn tab-btn",
                currentTab === FOLLOWING_TAB ? "active" : "",
              ].join(" ")}
              onClick={() => handleChangeTab(FOLLOWING_TAB)}
            >
              Following ({user.following?.length||0})
            </button>
          </div>
          <div className="tab-content p-5">{tabHtml}</div>
        </div>
      </div>
    </div>
  );
};

export default ProfileBottom;
