import axios from 'axios';
import {config} from './utils/env-constants'
const instance=axios.create({
    baseURL:config.API_URL
})

if (localStorage.userToken) {
    instance.defaults.headers.common["x-auth-token"] = localStorage.userToken;
  } else {
    delete instance.defaults.headers.common["x-auth-token"];
  }

export default instance;
