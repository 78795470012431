import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import { loadUser, startLoading } from './store/actions/auth';
import store from './store/store';
import { connect } from 'react-redux';
import Footer from './components/layouts/Footer/Footer';
import Alert from './components/layouts/Alert';
import Toast from './components/layouts/Toast';
import './App.css';
import { ToastProvider } from 'react-toast-notifications';
import Spinner from './components/layouts/Spinner';

import ProfilePage from './pages/ProfilePage/ProfilePage'
import Landing from './pages/Landing/Landing'
import LoggedInLanding from './pages/LoggedInLanding/LoggedInLanding'
import Login from './pages/Login/Login'
import Event from './pages/Event/Event'
import Register from './pages/Register/Register'
import SearchPage from './pages/SearchPage/SearchPage'
import UserProfile from './pages/UserProfile/UserProfile'
import SettingsTabs from './pages/SettingAndBillingTabs/SettingAndBillingTabs'
import Forget from './pages/Forget/Forget';
import PrivateRouter from './components/routing/PrivateRouter';
import ResponsiveNav from './components/layouts/header/ResponsiveNav';

function App(props) {


  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  return (
    <div>
   {(props.loading || props.reqLoading)?<Spinner cls="abs"/>:""}      
  <ToastProvider>
      <ResponsiveNav />
      <Alert />
      <Toast />
      <Switch>
        
        <Route exact path="/" component={props.isAuthenticated===true?LoggedInLanding :Landing} />
        <PrivateRouter exact path="/profile/:tab?/:operation?/:id?" component={ProfilePage } />
        <PrivateRouter exact path="/user/:username" component={UserProfile} />
        <Route exact path="/events" component={Event} />
        <Route exact path="/register" component={Register } />
        <Route exact path="/login" component={Login} />
        <Route exact path="/forget-password" component={Forget } />
        <PrivateRouter exact path="/settings/:tab?/:key?" component={SettingsTabs } />
        <PrivateRouter exact path="/search" component={SearchPage} />
      </Switch>
<Footer />
      </ToastProvider>
    </div>
  );
}

const mapStatetoProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
  loading: state.auth.reqLoading,
});
export default connect(mapStatetoProps, { startLoading })(App);
