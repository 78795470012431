import * as atypes from "./types";
// import { setAlert } from "./alert";
// import setAuthToken from "../../utills/setAuthToken";
import { setToast } from "./toast";
import { axios_createDog, axios_getUserDogs } from "../../utils/axios-routes";
import { loadUser } from "./auth";

// import Cookies from 'js-cookie'

export const loadDogs = () => async (dispatch) => {
  dispatch({
    type: atypes.START_LOADING,
  });

  axios_getUserDogs()
    .then((response) => {
      if (response.data.success) {
        dispatch({
          type: atypes.GET_DOGS,
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: atypes.STOP_LOADING,
        });
      }
    })
    .catch((err) => {});
};

export const createDog = (data, history, setShowModal) => async (dispatch) => {
  dispatch({
    type: atypes.START_LOADING,
  });

  axios_createDog(data)
    .then((response) => {
      if (response.data.success) {
        dispatch({
          type: atypes.SET_TOAST,
          payload: [{ msg: "Dog Added ", type: "success" }],
        });

        dispatch(loadDogs());
        dispatch(loadUser());
        if (history) {
          history.push("/profile/dogs");
        }
      } else {
        dispatch({
          type: atypes.SET_TOAST,
          payload: [
            { msg: "Something went wrong! Dog not added", type: "error" },
          ],
        });
      }

      dispatch({
        type: atypes.STOP_LOADING,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: atypes.STOP_LOADING,
      });

      console.log(err?.response?.data?.errors);
      if (err?.response?.data?.errors) {
        let errors = [];
        let ar_err = err.response.data.errors;
        console.log(ar_err);
        ar_err.map((e) => {
          errors.push({ msg: e.param + " : " + e.msg, type: "error" });
          return e;
        });
        dispatch(setToast(errors));
      } else {
        dispatch({
          type: atypes.SET_TOAST,
          payload: [
            { msg: "Something went wrong! od not added", type: "error" },
          ],
        });
      }
    });
};
