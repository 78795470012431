import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  axios_getUsernamesOfDogLikes,
  axios_getUsernamesOfResultLikes,
  axios_likeOrUnlikeNotification,
} from "../../../../utils/axios-routes";
import { DOG_PROFILE_PATH } from "../../../../utils/constants";
import { getAge } from "../../../../utils/functions";
import "../../../stylesheets/Dogx.css";
import UserNameModal from "../../../UsernameModal";

const Dog = (props) => {
  const [likes, setLikes] = useState(props?.dog?.likes || []);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [usernames, setUsernames] = useState([]);

  const isLike = (usernames) => {
    return (
      usernames.findIndex(
        (username) => username.username === props?.user?.username
      ) !== -1
    );
  };

  const arrangeLikes = (likes) => {
    if (likes) {
      const index = likes?.findIndex(
        (username) => username.username === props?.user?.username
      );
      if (index !== -1) {
        const likesTemp = [...likes];

        [likesTemp[0], likesTemp[index]] = [likesTemp[index], likesTemp[0]];
        setLikes(likesTemp);
        return;
      }
      setLikes(likes);
    }
  };
  useEffect(() => {
    arrangeLikes(props.dog?.likes);
  }, [props?.dog?.likes]);

  const loadUsernames = (id) => {
    handleOpen();

    axios_getUsernamesOfDogLikes(id).then((res) => {
      if (res.data.success) {
        const usernames = res.data.usernames?.map((username) => {
          return {
            ...username,
            username:
              props.user?.username === username.username
                ? `${username.username} (you)`
                : username.username,
          };
        });
        setUsernames(usernames);
      }
    });
  };

  const handleLikeClick = (id) => {
    axios_likeOrUnlikeNotification("dog", id).then((res) => {
      const tempLikes = [...likes];

      const index = tempLikes.findIndex((like) => like._id == props?.user?._id);

      if (res.data.like) {
        tempLikes.push({
          _id: props?.user?._id,
          username: props?.user?.username,
        });
      } else {
        tempLikes.splice(index, 1);
      }

      arrangeLikes(tempLikes);
      // setLikes(tempLikes);
    });
  };

  const isLiked = isLike(likes);
  return (
    <div className="DogX">
      <UserNameModal
        open={open}
        handleClose={handleClose}
        usernames={usernames}
      />
      <div className="row">
        <div className="col-12  col-lg-4 p-0">
          <div className="leftInfo">
            <div className="row">
              <div className="col-4 col-md-4">
                <div className="dog-img-div">
                  <button>
                    <img
                      src={DOG_PROFILE_PATH + props.dog.profilePic}
                      alt="Profile Imag"
                      className="dog-image"
                    />
                  </button>
                </div>
              </div>
              <div className=" col-8 col-md-8 ">
                <div>
                  <h6 className="name font-weight-bold">{props.dog.name}</h6>
                </div>
                <div className="breed-info">
                  <p>
                    <span>Breed </span> {props.dog?.breedID?.name}
                  </p>
                  <p>
                    <span>Age </span> {getAge(props.dog.dob)}
                  </p>
                </div>
              </div>
              <div className="col-12 mt-3">
                {/* <div className="breed-info">
                  {props?.dog?.registrationIds?.map((club) => {
                    return (
                      <p>
                        {club?.organization}
                        {": "} {club?.name} | {club?.number}
                      </p>
                    );
                  })}
                </div> */}
              </div>

              <div className="col-12 mt-3">
                <div className="like-info">
                  {likes?.length === 0 && (
                    <>
                      {
                        <i
                          onClick={() => handleLikeClick(props.dog._id)}
                          className={`icon fa${isLiked ? "" : "r"} fa-heart`}
                        ></i>
                      }{" "}
                      <p className=" multiname">
                        <strong>{0}</strong> likes
                      </p>
                    </>
                  )}
                  {likes?.length === 1 && (
                    <>
                      {
                        <i
                          onClick={() => handleLikeClick(props.dog._id)}
                          className={`icon fa${isLiked ? "" : "r"} fa-heart`}
                        ></i>
                      }{" "}
                      <p className=" multiname">
                        <Link to={"/user/" + likes[0].username}>
                          {" "}
                          <strong>{isLiked ? "You" : likes[0].username}</strong>
                        </Link>{" "}
                        like this
                      </p>
                    </>
                  )}
                  {likes?.length === 2 && (
                    <>
                      {
                        <i
                          onClick={() => handleLikeClick(props.dog._id)}
                          className={`icon fa${isLiked ? "" : "r"} fa-heart`}
                        ></i>
                      }{" "}
                      <p className=" multiname">
                        <Link to={"/user/" + likes[0].username}>
                          {" "}
                          <strong>{isLiked ? "You" : likes[0].username}</strong>
                        </Link>{" "}
                        and
                        <strong>
                          <Link to={"/user/" + likes[1].username}>
                            {" "}
                            {likes[1].username}
                          </Link>{" "}
                        </strong>{" "}<br/>
                        like this
                      </p>
                    </>
                  )}
                  {likes?.length > 2 && (
                    <>
                      <i
                        onClick={() => handleLikeClick(props.dog._id)}
                        className={`icon fa${isLiked ? "" : "r"} fa-heart`}
                      ></i>{" "}
                      <p className=" multiname">
                        <Link to={"/user/" + likes[0].username}>
                          {" "}
                          <strong>{isLiked ? "You" : likes[0].username}</strong>
                        </Link>
                        ,
                        <strong>
                          {" "}
                          <Link to={"/user/" + likes[1].username}>
                            {" "}
                            {likes[1].username}
                          </Link>
                          ,{" "}
                        </strong>
                        and
                        <br />
                        <button className="btn outline-none text-white" onClick={() => loadUsernames(props.dog._id)}>
                        <strong >
                          {" "}
                          {likes?.length - 2}
                        </strong> {" "}
                        others like this
                        </button>{" "}
                        
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-8 p-0">
          <div className="main-badge-container">
            <div className="count-badge">
              {props?.dog?.medalsLength} <i className="icon fa fa-medal "></i>
            </div>
            {Object.keys(props?.dog?.results || {}).map((club) => {
              return (
                <div className="org-container">
                  {/* <p className="organization">{club}:</p> */}

                  {Object.keys(props?.dog?.results[club]).map((program) => {
                    return (
                      <div className="row">
                        <p className="program mb-3 mt-1">
                          {club}
                          {": "} {program}
                        </p>

                        {props?.dog?.results[club][program].map((badge) => {
                          return (
                            <div className="col-6 col-md-6 col-lg-3 mb-3">
                              <i className="icon fa fa-medal"></i>{" "}
                              <span className="name">{badge}</span>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              );
            })}
            
            {Object.keys(props?.dog?.results).length===0&&<p><strong>No titles earned</strong></p>}
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStatetoProps = (state) => {
  return {
    user: state.auth.user,
  };
};
export default connect(mapStatetoProps, {})(Dog);
