import React, { useState, useEffect } from "react";
import { axios_getNotifications } from "../../../utils/axios-routes";
import "../../stylesheets/Posts.css";
import DogPosts from "./DogPost";
import ResultPosts from "./ResultPost";
import { connect } from "react-redux";
import { Box, Typography, Link } from "@mui/material";

const Posts = ({ user }) => {
  const [notifications, setNotifications] = useState([]);
  useEffect(() => {
    axios_getNotifications().then((res) => {
      const noti = res.data.notifications.map((n) => {
        n.liked = false;
        let nn = n.likes.filter((l) => {
          if (user?._id === l) {
            return true;
          }
        });
        if (nn.length > 0) {
          n.liked = true;
        }
        return n;
      });

      setNotifications(noti);
    });
  }, []);

  const renderPosts = () => {
    console.log("Notifications: ", notifications);
    if (notifications.length > 0) {
      return notifications.map((post) => {
        if ("dog" in post) return <ResultPosts post={post} />;
        else {
          return <DogPosts post={post} />;
        }
      });
    } else {
      return (
        <Box sx={{width: "90%"}}>
          <Typography variant="subtitle1" sx={{paddingTop: '1rem'}}>
            You have no updates. 
            <br/>
            <Link href="/search" underline="hover">Follow your friends</Link> to get updates.
          </Typography>
        </Box>
      );
    }
  };

  return <div className="Posts">{renderPosts()}</div>;
};

const mapStatetoProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
  user: state.auth.user,
});
export default connect(mapStatetoProps, {})(Posts);
