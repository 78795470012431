import React, { useEffect, useState } from "react";
import '../../stylesheets/Dogs.css';
import AddDog from "./AddDog/AddDog";
import AddClub from "./AddClub/AddClub";
import DogList from "./DogList/DogList";

import { connect } from "react-redux";
import { createDog, loadDogs } from "../../../store/actions/dogs";
import dogProfilePic from "../../img/add-dog/dogavatar2.png";
import { DOG_PROFILE_PATH } from "../../../utils/constants";
import { loadUser } from "../../../store/actions/auth";
import { useHistory, useLocation, useParams } from "react-router-dom";
import EditDog from "./EditDog/EditDog";
import AddMultiClubs from "./AddMultiClubs";


const initialData={
  organization: "",
  name: "",
  number: "",
}
const DogRegisteration = (props) => {
  const [addingDog, setaddingDog] = useState(false);
  const [editingDog, seteditingDog] = useState(false);
  const [addingClub, setaddingClub] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const history = useHistory();
  const params = useParams();
  // console.log(props);
  const [data, setData] = useState({
    breed: "",
    owner: "",
    dob: "",
    height: "",
    weight: "",
    profilePic: DOG_PROFILE_PATH + "dummy-profile.png",
    orignalPic: {},
    clubs: [],
  });

  console.log("main")
  console.log(data.clubs)
  useEffect(() => {
    // if(params.operation==='add'){
    //   setaddingDog(true);
    // }
  }, [params.operation]);

  const onHide = () => {
    setShowModal(false);
  };

  const onAccept = () => {
    history.push("/settings/billing");
    setShowModal(false);
  };

  const onAddDogClick = (e) => {
    // // setaddingDog(true);
    // axios_checkDogAllowed().then((res) => {
    //   // console.log(res.data);
    //   if (res.data.allowed) {
        history.push("/profile/dogs/add");
      // } else {
        // setShowModal(true);
      // }
    // });
  };
  const onSubmitAddDogForm = (e) => {
    setaddingClub(true);
  };

  const p = useParams();
  // const l=useLocation()

  // console.log(p);

  // console.log(props);

  const handleFormSubmit = (e) => {
    e.preventDefault();

    setTimeout(() => {
      // history.push('/profile/dogs')
      setaddingDog(false);
      setaddingClub(false);
      setShowForm(false);
      setData({
        breed: "",
        owner: "",
        dob: "",
        height: "",
        weight: "",
        profilePic: dogProfilePic,
        clubs:[],
      });
    }, 2000);

    const formData = new FormData();
    formData.append("file", data.orignalPic); // appending file
    let newData = { ...data };
    delete newData.orignalPic;
    delete newData.profilePic;
    formData.append("data1", JSON.stringify(newData)); // appending file
    props.createDog(formData, history,setShowModal);
  };

  const onClickInfo = () => {
    setShowForm(true);
    const tempClubs=[...data.clubs]
    console.log("test")
    console.log(tempClubs)

    tempClubs.push({...initialData})
    console.log(tempClubs)
    setData({...data,clubs:tempClubs})
  };

  console.log(data)
  let html =
    addingDog === false ? (
      <DogList onAddDogClick={onAddDogClick} />
    ) : (
      <div>
        {addingClub === false ? (
          <AddDog
            data={data}
            setData={setData}
            setaddingDog={setaddingDog}
            onSubmitAddDogForm={onSubmitAddDogForm}
          />
        ) : (
          <AddMultiClubs
            data={data}
            handleFormSubmit={handleFormSubmit}
            setaddingClub={setaddingClub}
            setaddingDog={setaddingDog}
            setData={setData}
            showForm={showForm}
            onClickInfo={onClickInfo}
          />
        )}
      </div>
    );

  if (editingDog === true) {
    html = (
      <div className="mt-5">
        <EditDog
          data={data}
          setData={setData}
          setaddingDog={setaddingDog}
          onSubmitAddDogForm={onSubmitAddDogForm}
        />
      </div>
    );
  }

  useEffect(() => {
    if (params.operation === "add") {
      setaddingDog(true);
    } else if (params.operation === "edit") {
      seteditingDog(true);
    } else if (params.operation === undefined) {
      setaddingDog(false);
      seteditingDog(false);
      setaddingClub(false);
    }
  }, [params.operation]);

  return (
    <div className="Dogs">

      {html}
    </div>
  );
};

const mapStatetoProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
  dogs: state.dogs.dogs,
});
export default connect(mapStatetoProps, { createDog, loadDogs, loadUser })(
  DogRegisteration
);
