import React, { useEffect, useState } from "react";
import "../../stylesheets/ResultList.css";
import MUIDataTable from "mui-datatables";
import dateFormat from "dateformat";
import { styled } from "@mui/system";
import {
  axios_getUsernamesOfResultLikes,
  axios_likeOrUnlikeNotification,
} from "../../../utils/axios-routes";
import { Typography, IconButton } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { connect } from "react-redux";
import UserNameModal from "../../UsernameModal";

const Root = styled("div")(
  ({ theme }) => `

  margin-bottom:20px;

 
  table {
    border-collapse: collapse;
    width: 100%;
    border:1px solid gray;
  }
  th {
    background-color:lightgrey;
    font-weight:900;
  }


  .MuiIconButton-label{
    background-color:var(--secondary-color);
    padding:6px;
    border-radius:50%;
    border:1px solid var(--secondary-color);
   
    &:hover{
       
      border:1px solid var(--secondary-color);
      background-color:white;
    
      .MuiSvgIcon-root{
     
          color:var(--primary-color);
        
      }

    }

    .MuiSvgIcon-root{
      color:white;
      &:hover{
        color:var(--primary-color);
      }
    }
    
  }

   .action-buttons{

      .action-btn{
        padding:8px; 
        opacity: 80%;
        color: white;
        font-size:0.75em;
        margin:2px;
        border-radius:50%;
       &:hover{
         box-shadow:0 0 10px lightgray;
         transform:scale(1.1);
       }
      }

      .edit-button{
        background-color:green;
      }
      .delete-button{
        background-color:red;
      }
}

  `
);

const ResultList = (props) => {
  const [titleOnly, setTitleOnly] = useState(false);

  const columns = [
    "Dog",
    "Club",
    "Sport",
    "Level",
    "Date",
    "Placement",
    { name: " ", options: { filter: false } },
    {
      name: "  ",
      options: {
        display:false,
        filter: true,
        customFilterListOptions: { render: v => `Showing Titles Only` },
        filterType: "checkbox",
        filterOptions: {
          names: [true], // only 1 checkbox with value === true
          logic(active, filterVal, row) {
            // setTitleOnly(!titleOnly)

            // Note: filterVal is an array of the values selected in the filter
            return !row[7];
          },
          renderValue: val => {
            // console.log(val);
            if (val === true) {
              return "Show Titles Only";
            }
            // return "Show Titles Only"
          }
        },
        customBodyRender: active => {
          if (active) {
            return (
              <div style={{ textAlign: "center" }}>
                {/* <CheckIcon /> */} check
              </div>
            );
          }
          return null;
        }
      }
    }

  ];

  const [resultsList, setResultList] = useState([]);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [usernames, setUsernames] = useState([]);

  useEffect(() => {
    if (props.results) setResultList(props.results);
  }, [props.results]);
  // const [liked, setLiked] = useState(false);
  // const [likedC, setLikedC] = useState();

  // useEffect(() => {
  //   setLiked(post.liked);
  // }, [post.liked]);

  const handleLikeClick = (id) => {
    axios_likeOrUnlikeNotification("result", id).then((res) => {
      const results = [...resultsList];

      console.log(id);
      const index1 = results.findIndex((result) => result._id == id);
      const index = results[index1].likes.findIndex(
        (userid) => userid == props?.user?._id
      );
      console.log(index1, index);
      console.log(results[index]);
      if (res.data.like) {
        results[index1] = {
          ...results[index1],
          liked: true,
          likes: [...results[index1].likes, props?.user?._id],
        };
      } else {
        const likes = [...results[index1].likes];

        likes.splice(index, 1);

        results[index1] = {
          ...results[index1],
          liked: false,
          likes: likes,
        };
      }
      setResultList(results);
    });
  };

  const loadUsernames = (id) => {
    handleOpen();
    axios_getUsernamesOfResultLikes(id).then((res) => {
      if (res.data.success) {
        const usernames = res.data.usernames?.map((username) => {
          return {
            ...username,
            username:
              props.user?.username === username.username
                ? `${username.username} (you)`
                : username.username,
          };
        });
        setUsernames(usernames);
      }
    });
  };
  const mapList = (results) => {
    return results?.map((result) => {
      const date = result.date
        ? dateFormat(result.date, "UTC:mmmm dd yyyy")
        : "";
      // const vehicle = result.vehicle !== undefined ? result.vehicle : "";
      // const element = result.element;
      // const level = result.level + " " + element + " " + vehicle;
      // console.log("id",result._id);
      const likes = () => {
        return (
          <div className="action-buttons ">
            <IconButton
              disabled={props?.user?._id === result.userId}
              aria-label="like post"
              onClick={() => handleLikeClick(result._id)}
            >
              <FavoriteIcon color={result.liked ? "primary" : " "} />{" "}
            </IconButton>{" "}
            <Typography
              style={{ fontSize: "16px", fontWeight: 600 }}
              variant="strong"
            >
              <a
                href="#"
                className="likes-link"
                onClick={() => loadUsernames(result._id)}
                style={{ color: `var(--dark2-color)`, textDecoration: "none" }}
                title="View users"
              >
                {result?.likes?.length} likes
              </a>
            </Typography>
          </div>
        );
      };
      const level = result.level;
      return {
        Dog: result.dog.name,
        Club: result.organization,
        Sport: result.program,
        Level: level,
        Date: date,
        Placement: result.badge,
        "  ": result.earned,
        " ": likes,
      };
    });
  };

  const options = {
    selectableRows: "none",
    search: false,
    filter: true,
    filterType: "dropdown",
    download: false,
    print: false,
    viewColumns: false,
    elevation: 0,
  };

  return (
    <div>
      <UserNameModal
        open={open}
        handleClose={handleClose}
        usernames={usernames}
      />
      <div className="events-parent">
        {props?.results?.length > 0 ? (
          <Root>
            <MUIDataTable
              title={""}
              selectableRowsHideCheckboxes={false}
              data={mapList(resultsList)}
              columns={columns}
              options={options}
            />
          </Root>
        ) : (
          <p className="para font-weight-bold light-color">
            No results recorded
          </p>
        )}
      </div>
    </div>
  );
};

const mapStatetoProps = (state) => {
  return {
    user: state.auth.user,
  };
};
export default connect(mapStatetoProps, {})(ResultList);
