import React from "react";
import { Grid } from "@mui/material";
import Benefitspawcker from "../../components/Landing/Benefitspawcker";
import HeroPanel from "../../components/Landing/HeroPanel";

const Landing = () => {
  return (
    <div id="Landing">
      <HeroPanel />
      <Grid
        style={{ marginTop: "3rem", marginBottom: "4rem", textAlign: "center" }}
      >
        <Benefitspawcker />
      </Grid>
    </div>
  );
};

export default Landing;
