import React, { useState } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";

const ModalBadge = ({onHide,show,title,body,onCancel,onAccept,noText,yesText,...rest}) => {

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={rest.style?{...rest.style}:{}}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <h4>Centered Modal</h4> */}
        <p>
          {body}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn" style={{background:"white",border:"none",color:"black"}} onClick={onCancel}>{noText?noText:"No"}</Button>
        <Button className="btn btn-succes" style={{backgroundColor:"var(--cta-color)",border:"none"}} disabled={rest.loading}  onClick={onAccept}>{rest?.loading?"wait...":<>{yesText?yesText:"Yes"}</>} </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default ModalBadge;