import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  startLoading,
  stopLoading,
  loadUser,
} from "../../../../store/actions/auth";
import { setToast } from "../../../../store/actions/toast";
import {
  axios_cloneResult,
  axios_deleteResult,
  axios_getPendingResult,
  axios_getUsernamesOfResultLikes,
  axios_getUserResults,
  axios_likeOrUnlikeNotification,
  axios_updatePendingBadgeToAdd,
} from "../../../../utils/axios-routes";
import ModalBadge from "../../../layouts/ModalBadge/ModalBadge";
import MUIDataTable from "mui-datatables";
import { Link } from "react-router-dom";
import "../../../stylesheets/Result.css";
import dateFormat from "dateformat";

import { styled } from "@mui/system";
import UserNameModal from "../../../UsernameModal";
import { Typography, IconButton } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
const Root = styled("div")(
  ({ theme }) => `

  margin-bottom:20px;

 
  table {
    border-collapse: collapse;
    width: 100%;
    border:1px solid gray;
  }
  th {
    background-color:lightgrey;
    font-weight:900;
  }


  .MuiIconButton-label{
    background-color:var(--secondary-color);
    padding:6px;
    border-radius:50%;
    border:1px solid var(--secondary-color);
   
    &:hover{
       
      border:1px solid var(--secondary-color);
      background-color:white;
    
      .MuiSvgIcon-root{
     
          color:var(--primary-color);
        
      }

    }

    .MuiSvgIcon-root{
      color:white;
      &:hover{
        color:var(--primary-color);
      }
    }
    
  }

   .action-buttons{

      .action-btn{
        padding:8px; 
        opacity: 80%;
        color: white;
        font-size:0.75em;
        margin:2px;
        border-radius:50%;
       &:hover{
         box-shadow:0 0 10px lightgray;
         transform:scale(1.1);
       }
      }

      .edit-button{
        background-color:green;
      }
      .copy-button{
        background-color:var(--cta-color);
      }
      .delete-button{
        background-color:red;
      }
}

  `
);

const ResultList = (props) => {
  const [results, setresults] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [modalShowCopy, setModalShowCopy] = useState(false);
  const [seletedCopy, setSelectedCopy] = useState();
  const [modalData, setModalData] = useState({
    title: "",
    body: "",
    result: {},
  });

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [usernames, setUsernames] = useState([]);

  const [pendingResults, setPendingResults] = useState([]);

  const onHide = () => {
    setModalShow(false);
  };

  const onAccept = () => {
    axios_updatePendingBadgeToAdd(modalData.result._id).then((res) => {
      const pendings = [...pendingResults];
      pendings.splice(0, 1);
      setPendingResults(pendings);

      if (pendings.length === 0) {
        loadingResults();
      }
    });

    onHide();
  };

  const onCancel = () => {
    axios_deleteResult(modalData.result._id).then((res) => {
      const pendings = [...pendingResults];
      pendings.splice(0, 1);
      setPendingResults(pendings);
    });
    onHide();
  };


  const onHideCopy = () => {
    setModalShowCopy(false);
  };

  const onAcceptCopy = () => {
    props.startLoading()
    axios_cloneResult({}, seletedCopy._id).then((res) => {
      loadingResults();
      props.setToast([{ type: "success", msg: "Result successfully cloned" }]);
      onHideCopy();

      checkPendingResults();
    });
  };

  const onCancelCopy = () => {

    onHideCopy();
  };
  const loadingResults = () => {
    props.startLoading();
    axios_getUserResults().then((res) => {
      props.stopLoading();

      if (res.data.success) setresults(res.data.results);
    });
  };

  const checkPendingResults = () => {

    axios_getPendingResult().then((res) => {
      // console.log(res.data);
      if (res.data.success) {
        setPendingResults(res.data.results);
      }
    });
  }


  useEffect(() => {
    if (pendingResults.length > 0) {
      setModalShow(true);
      setModalData({
        title: "Earned new title",
        body: (
          <span>
            You have earned a new title:{" "}
            <strong>{pendingResults[0].badge}.</strong>
            <br />
            Do you want to add to your profile?
          </span>
        ),
        result: pendingResults[0],
      });
    }
  }, [pendingResults]);



  useEffect(() => {
    loadingResults();
    checkPendingResults();
  }, []);

  const handleRemoveResult = (id) => {
    props.startLoading();

    axios_deleteResult(id).then((res) => {
      // console.log(res.data);
      if (res.data.success) {
        axios_getUserResults().then((res) => {
          props.stopLoading();
          props.loadUser();
          if (res.data.success) setresults(res.data.results);
          props.setToast([{ type: "success", msg: "Result Deleted" }]);
        });
      }
    });
  };

  const handleCopyresult = (result) => {

    setModalShowCopy(true)
    setSelectedCopy(result)
  }
  const loadUsernames = (id) => {
    handleOpen();

    axios_getUsernamesOfResultLikes(id).then((res) => {
      if (res.data.success) {
        const usernames = res.data.usernames?.map((username) => {
          return {
            ...username,
            username:
              props.user?.username === username.username
                ? `${username.username} (you)`
                : username.username,
          };
        });
        setUsernames(usernames);
      }
    });
  };

  const columns = [
    "Dog",
    "Club",
    "Sport",
    "Level",
    { name: "Date", options: { filter: false } },
    "Placement",
    { name: " ", options: { filter: false } },
    {
      name: "Actions",
      options: { filter: false, sort: false, style: { textAlign: "center" } },
    },
    {
      name: "  ",
      options: {
        display: false,
        filter: true,
        customFilterListOptions: { render: v => `Showing Titles Only` },
        filterType: "checkbox",
        filterOptions: {
          names: [true], // only 1 checkbox with value === true
          logic(active, filterVal, row) {
            // Note: filterVal is an array of the values selected in the filter
            return !row[8];
          },
          renderValue: val => {
            console.log(val);
            if (val === true) {
              return "Show Titles Only";
            }
          }
        },
        customBodyRender: active => {
          if (active) {
            return (
              <div style={{ textAlign: "center" }}>
                {/* <CheckIcon /> */} check
              </div>
            );
          }
          return null;
        }
      }
    }

  ];

  let resultList = results.map((result) => {
    const date = result.date ? dateFormat(result.date, "UTC:mmmm dd yyyy") : "";
    let cdsaElements = result.elements.join(",");
    let noBadge = result.organization === "CDSA" ? cdsaElements : result.placement
    let placement = result.badge ? result.badge : noBadge;

    // const vehicle = result.vehicle !== undefined ? result.vehicle : "";
    // const element = result.element;
    // const level = result.level + " " + element + " " + vehicle;
    const level = result.level;
    const edit = () => {
      return (
        <div className="action-buttons d-flex justify-content-end ">
          {result?.earned === false && (
            <>
              <i className="fa fa-copy copy-button action-btn" onClick={() => handleCopyresult(result)} ></i>
              <Link to={"/profile/results/edit/" + result?._id}>
                <i className="fa fa-pen edit-button action-btn"></i>
              </Link>
            </>

          )}

          <i
            className="fa fa-trash delete-button action-btn"
            onClick={() => handleRemoveResult(result?._id)}
          ></i>
        </div>
      );
    };

    const likes = () => {
      return (
        <div className="action-buttons " >
          <IconButton disabled={true} aria-label="like post">
            <FavoriteIcon color={" "} />{" "}
          </IconButton>{" "}
          <Typography
            style={{ fontSize: "16px", fontWeight: 600 }}
            variant="strong"
          >
            <a
              href="#"
              className="likes-link"
              onClick={() => loadUsernames(result._id)}
              style={{ color: `var(--dark2-color)`, textDecoration: "none" }}
              title="View users"
            >
              {result?.likes?.length} likes
            </a>
          </Typography>
        </div>
      );
    };

    let program = result.program;

    if (result.organization === 'Agility Association of Canada (AAC)') {
      program = result.class

    }

    return {
      Dog: result.dog.name,
      Club: result.organization,
      Sport: program,
      Level: level,
      Date: date,
      " ": likes,
      Placement: placement,
      "  ": result.earned,

      Actions: edit,
    };
  });

  const options = {
    selectableRows: false,
    search: true,
    filter: true,
    filterType: "dropdown",
    download: true,
    print: false,
    viewColumns: true,
    elevation: 0,
  };

  return (
    <div>
      <UserNameModal
        open={open}
        handleClose={handleClose}
        usernames={usernames}
      />
      <ModalBadge
        title={modalData.title}
        body={modalData.body}
        onAccept={onAccept}
        on
        onCancel={onCancel}
        onHide={onHide}
        show={modalShow}
      />
      <ModalBadge
        title={""}
        body={
          <>
            <h5>Are you sure you want to clone result?</h5>
            <div className="p-3 m-3" style={{ backgroundColor: "lightgray" }}>
              <p className="m-0"><strong>Dog : </strong>{seletedCopy?.dog?.name}</p>
              <p className="m-0"><strong>Club : </strong>{seletedCopy?.organization}</p>
              <p className="m-0"><strong>Sport : </strong>{seletedCopy?.program == 'Agility' ? seletedCopy?.class : seletedCopy?.program}</p>
              {seletedCopy?.level && <p className="m-0"><strong>Level : </strong>{seletedCopy?.level}</p>}
              {seletedCopy?.date && <p className="m-0"><strong>Date : </strong>{dateFormat(seletedCopy?.date, "UTC:mmmm dd yyyy")}</p>}            </div>
          </>
        }
        loading={props.loading}
        onAccept={onAcceptCopy}
        onCancel={onCancelCopy}
        onHide={onHideCopy}
        show={modalShowCopy}
      />
      <div className="events-parent">
        <div style={{ overflow: "hidden" }}>
          <button className="add-btn" onClick={(e) => props.onAddResultClick()}>
            <i className="fas fa-plus-square"></i>
          </button>
        </div>

        {results.length > 0 ? (
          <Root>
            <MUIDataTable
              title={""}
              selectableRowsHideCheckboxes={false}
              data={resultList}
              columns={columns}
              options={options}
            />
          </Root>
        ) : (
          <p className="para font-weight-bold light-color">No results added</p>
        )}
      </div>
    </div>
  );
};

const mapStatetoProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
});
export default connect(mapStatetoProps, {
  startLoading,
  stopLoading,
  loadUser,
  setToast,
})(ResultList);
