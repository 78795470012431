import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import "../stylesheets/UsernameModal.css";
import { Link } from "react-router-dom";
import { USER_PROFILE_PATH } from "../../utils/constants";
const style = {
  position: "absolute",
  top: "30%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
};

const UserNameModal = ({
  open,
  setOpen,
  handleClose,
  handleOpen,
  usernames,
}) => {
  return (
    <div className="UsernameModal">
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="modal-container">
            <div className="px-3 pt-3 pb-1">
              <h3>Likes</h3>
              <button className="close-btn" onClick={handleClose}>
                <i className="fa fa-times"></i>
              </button>
            </div>
            <hr />

            <div className="username-list">
              {usernames?.map((username) => {
                return (
                 <>
                 <div className="username-item px-3">
                    <div className="username-img-div">
                      <button>
                        <img
                          src={USER_PROFILE_PATH + username?.profilePic}
                          alt="Profile Imag"
                          className="username-image"
                        />
                      </button>
                    </div>
                    <div className="names">
                      <a href={"/user/" + username?.username}>
                        {username?.username}
                      </a>
                    </div>

                  </div>
                  <hr/>
                  </>
                );
              })}
              {usernames?.length===0&&<p className="text-center pt-5"><strong>No likes found</strong></p>}
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default UserNameModal;
