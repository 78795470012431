export const getAge=(dateString)=> 
{
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();

      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
    {
        age--;
    }


    return (age>0?age+"y ":"") + (Math.abs(m)!==0?Math.abs(m)+"m":"");
}



export const compareStrings=(a, b)=> {
    // Assuming you want case-insensitive comparison
    a = a.toLowerCase();
    b = b.toLowerCase();
  
    return (a < b) ? -1 : (a > b) ? 1 : 0;
  }
export const sortData=(data)=>{

  return data.sort(function(a, b) {
        return compareStrings(a.name, b.name);
      })

}
  
  